<template>
	<div class="w-100 bg-background">
        <v-container class="my-16">
            <v-card width="1400" elevation="0" class="mx-auto" color="transparent">
                <v-row gutters justify="center" class="mt-8">
                    <v-col cols="12" align="center" class="mr-4 ml-4">
                    <div class="home-h2 mb-10">Media</div>
                    <Media></Media>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
        <v-divider></v-divider>
        <v-container class="scroll-section py-0 ml-16 mt-8" v-if="responsive == 'pc'">
            <v-card width="1400" elevation="0" class="mx-auto" color="transparent">
                <v-row no-gutters class="pb-10">
                    <v-col cols="3">
                        <div class="pointer pl-1">
                            <!-- <router-link to="/">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/51f2c22a-834b-42c1-ebf6-ef6bba50d500/public" width="200" height="71"></v-img>
                            </router-link> -->
                            <router-link to="/">
                                <div class="logo" v-show="this.darkTheme == 0" >
                                    <logo type="footer" color="light" responsive="pc"></logo>
                                </div>
                                <div class="logo" v-show="this.darkTheme == 1" >
                                    <logo type="footer" color="dark" responsive="pc"></logo>   
                                </div>
                            </router-link>
                        </div>
                        <div>
                           <div class="ending-zero-sum body-p-small mt-2">
                                more for you is also more for me™
                           </div> 
                           <div class="mt-4 d-flex">
                                <div>
                                    <a href="https://x.com/untradingOrg" target="_blank" class="del-underline">
                                        <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/d63c1182-e0bd-44cd-d5ad-7334f9b6b100/public"  aria-label="X (Twitter)" role="img" width="24" height="24"></v-img>
                                    </a>
                                </div>
                                <div class="ml-1">
                                    <a href="https://www.instagram.com/untrading.app/" target="_blank" class="del-underline" aria-label="Instagram">
                                        <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/841e6e5a-eb0f-4601-d9aa-bfc462a03300/public" width="24" height="24"></v-img>
                                    </a>
                                </div>
                                <div class="ml-1">
                                    <a href="https://www.pinterest.com/untradingApp/" target="_blank" class="white--text del-underline" aria-label="Pinterest">
                                        <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/b95f9942-7ecc-4d99-5359-39369aa56f00/public" width="24" height="24"></v-img>
                                    </a>
                                </div>
                                <div class="ml-1">
                                    <a href="https://www.facebook.com/untrading.protocol" class="del-underline" target="_blank" aria-label="Facebook">
                                        <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/8da11148-c4cc-4bbe-00bb-14280dbfa000/public" width="24" height="24"></v-img> 
                                    </a>
                                </div>
                                <div class="ml-1">
                                    <a href="https://www.reddit.com/r/untrading/" target="_blank" class="black--text del-underline" aria-label="Reddit">
                                        <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/bef4a6c1-ee0d-4bf2-d0f1-a44cdc3a4900/public" width="24" height="24"></v-img>
                                    </a>
                                </div>
                                <div class="ml-1">
                                    <a href="https://www.linkedin.com/company/untrading/" target="_blank" class="black--text del-underline" aria-label="Linkedin">
                                        <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0b270d74-2b67-43b6-2a9a-9e9af19a6d00/public" width="24" height="24"></v-img>
                                    </a>
                                </div>
                            </div>
                            <div class="d-flex mt-4">
                                <div>
                                    <a href="https://github.com/unTrading" target="_blank" class="black--text del-underline" aria-label="Github">
                                        <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/48737c90-42b0-4069-a44a-e3a4616fdb00/public" width="24" height="24"></v-img>
                                    </a>
                                </div>
                                <div class="ml-1">
                                    <a href="https://t.me/untrading" target="_blank" class="black--text del-underline" aria-label="Telegram">
                                        <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/88bd42d6-38b2-4775-ebdc-b5d28b927500/public" width="24" height="24"></v-img>
                                    </a>
                                </div>
                                <div class="ml-1">
                                    <a href="https://discord.gg/D5rZdhzVWh" target="_blank" class="black--text del-underline" aria-label="Discord">
                                        <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ac803eba-9354-40bd-3de0-1c6ab1cc6d00/public" width="24" height="24"></v-img>
                                    </a>
                                </div>
                            </div>
                            <div class="mt-4 d-flex">
                                <div>
                                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/570b4fc3-a9a5-4171-a286-c39ea70eb300/public" cover width="90" v-show="this.darkTheme == 0" class="mr-6"></v-img>
                                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/b74fc7e2-76e6-4886-dab5-4a915d291900/public" cover width="90" v-show="this.darkTheme == 1" class="mr-6"></v-img>
                                </div>
                                <div>
                                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ad711f74-e320-42e4-a346-1473c1566f00/public" cover width="90" v-show="this.darkTheme == 0"></v-img>
                                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/8e64e336-d5ef-4fc4-48a7-3fa7eb9f8c00/public" cover width="90" v-show="this.darkTheme == 1"></v-img>
                                </div>
                            </div>
                            <div class="mt-1 d-flex">
                                <div>
                                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/56abb3aa-9120-40b3-efdf-34300a8e1700/public" cover width="90" v-show="this.darkTheme == 0" class="mr-6"></v-img>
                                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/f2fd4477-500d-4a8e-a78c-a6b69610ab00/public" cover width="90" v-show="this.darkTheme == 1" class="mr-6"></v-img>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="9">
                        <v-row no-gutters class="pb-5" justify="end">
                            <v-col cols="4" justify="center" class="hover-underline">
                                <h2 class="body-p-medium text-primaryGrey font-weight-bold">
                                    untrading: The Future of Tokenized Markets
                                </h2>
                                <div class="mt-4 body-p text-primaryGrey">
                                    untrading Technologies Pte Ltd is a Singapore-based company that operates a decentralized marketplace and provides technical services leveraging Ethereum technology. We unlock the continued value of your investments with our Provenance Value Amplification (PVA) system. 
                                </div>
                                <div class="mt-4 body-p text-primaryGrey">
                                    Our platform offers a unique offering for cryptocurrency and NFT token holders, powered by the ERC-5173 protocol we introduced in 2022. Specifically, our commission-free crypto investment platform allows these token owners to benefit from future price appreciation even after they have sold their tokens. 
                                </div>
                                <div class="mt-4 body-p text-primaryGrey">
                                    At untrading.org, we are embarking on a new era of trading, where innovation and opportunity intersect. Experience the future today and unleash the full potential of your crypto investments with us.
                                </div>
                            </v-col>
                            <v-col cols="4" justify="center" class="pl-90 gradient-underline-hover body-p">
                                <div>
                                    <router-link to="/" class="text-primaryGrey gradient-text-hover del-underline">
                                        Home
                                    </router-link>
                                </div>
                                <div class="mt-4">
                                    <router-link to="/uncryptos" class="text-primaryGrey gradient-text-hover del-underline">
                                        Cryptos
                                    </router-link>
                                </div>
                                <div class="mt-4">
                                    <router-link to="/unnfts" class="text-primaryGrey gradient-text-hover del-underline">
                                        NFTs
                                    </router-link>
                                </div>
                                <div class="mt-4">
                                    <a href="https://eips.ethereum.org/EIPS/eip-5173" target="_blank" class="text-primaryGrey del-underline">
                                        <span class="gradient-text-hover">ERC-5173</span>
                                    </a>
                                </div>
                                <div class="mt-4">
                                    <a href="https://docs.untrading.org/whitepaper" target="_blank" class="text-primaryGrey del-underline">
                                        <span class="gradient-text-hover">Whitepaper</span>
                                    </a>
                                </div>
                                <div class="mt-4">
                                    <a href="https://docs.untrading.org/faqs/general" target="_blank" class="text-primaryGrey gradient-text-hover del-underline">
                                        FAQ
                                    </a>
                                </div>
                                <div class="mt-4">
                                    <a href="https://docs.untrading.org/tutorials" target="_blank" class="text-primaryGrey gradient-text-hover del-underline">
                                        Tutorials
                                    </a>
                                </div>
                            </v-col>
                            <v-col cols="4" justify="center" class="gradient-underline-hover body-p">
                                <div>
                                    <a href="https://docs.untrading.org/risks" target="_blank" class="text-primaryGrey del-underline">
                                        <span class="gradient-text-hover">Risks</span>
                                    </a>
                                </div>
                                <div class="mt-4">
                                    <a href="https://docs.untrading.org/community-guidelines" target="_blank" class="text-primaryGrey del-underline">
                                        <span class="gradient-text-hover">Community Guidelines</span>
                                    </a>
                                </div>
                                <div class="mt-4">
                                    <a href="https://docs.untrading.org/privacy-policy" target="_blank" class="text-primaryGrey del-underline">
                                        <span class="gradient-text-hover">Privacy Policy</span>
                                    </a>
                                </div>
                                <div class="mt-4">
                                    <a href="https://docs.untrading.org/terms-of-service" target="_blank" class="text-primaryGrey del-underline">
                                        <span class="gradient-text-hover">Terms of Service</span>
                                    </a>
                                </div>
                                <div class="mt-4">
                                    <a :href="$tools.getTestnetAppUrl(darkTheme, locale)" class="text-primaryGrey del-underline">
                                        <span class="gradient-text-hover">Testnet</span>
                                    </a>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
        <v-divider v-if="responsive == 'pc'"></v-divider>
        <v-container class="px-0 mt-2" v-if="responsive == 'pc'">
            <div class="body-p-small text-primaryGrey d-flex flex-nowrap align-center pl-6">
                <span>
                    <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank" class="del-underline pointer" aria-label="Licenses">
                        <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/8554187d-f5dc-41f7-eb9e-637396d9d100/public" cover class="mb-3" width="80" alt="" />
                    </a>
                </span>
                <span class="pl-4 mb-2">
                    Except where otherwise noted, content on this site is licensed under a Creative Commons Attribution 4.0 International license.
                </span>
            </div>
        </v-container>
        <v-container class="px-0 ml-6 pt-16" v-if="responsive == 'mobile'">
            <v-row no-gutters>
                <v-col cols="10">
                    <div class="pointer">
                        <!-- <router-link to="/">
                            <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/51f2c22a-834b-42c1-ebf6-ef6bba50d500/public" width="200" height="71"></v-img>
                        </router-link> -->
                        <router-link to="/">
                            <div class="logo" v-show="this.darkTheme == 0" >
                                <logo type="footer" color="light" responsive="pc"></logo>
                            </div>
                            <div class="logo" v-show="this.darkTheme == 1" >
                                <logo type="footer" color="dark" responsive="pc"></logo>   
                            </div>
                        </router-link>
                    </div>
                </v-col>
            </v-row>
            <v-row class="pb-5">
                <v-col cols="10">
                    <div class="ending-zero-sum">
                        more for you is also more for me™
                    </div>
                </v-col>
                <v-col cols="9" class="d-flex flex-nowrap justify-space-between mt-6">
                    <div>
                        <a href="https://x.com/untradingOrg" target="_blank" class="del-underline" aria-label="Twitter">
                            <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/d63c1182-e0bd-44cd-d5ad-7334f9b6b100/public" width="24" height="24"></v-img>
                        </a>
                    </div>
                    <div>
                        <a href="https://www.instagram.com/untrading.app/" target="_blank" class="del-underline" aria-label="Instagram">
                            <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/841e6e5a-eb0f-4601-d9aa-bfc462a03300/public" width="24" height="24"></v-img>
                        </a>
                    </div>
                    <div>
                         <a href="https://www.pinterest.com/untradingApp/" target="_blank" class="del-underline"  aria-label="Pinterest">
                            <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/b95f9942-7ecc-4d99-5359-39369aa56f00/public" width="24" height="24"></v-img>
                        </a>
                    </div>
                    <div>
                        <a href="https://www.facebook.com/untrading.protocol" class="del-underline" target="_blank" aria-label="Facebook">
                            <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/8da11148-c4cc-4bbe-00bb-14280dbfa000/public" width="24" height="24"></v-img>  
                       </a>
                    </div>
                    <div>
                        <a href="https://www.reddit.com/r/untrading/" target="_blank" class="del-underline" aria-label="Reddit">
                            <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/bef4a6c1-ee0d-4bf2-d0f1-a44cdc3a4900/public" width="24" height="24"></v-img> 
                        </a>
                    </div>
                    <div>
                        <a href="https://www.linkedin.com/company/untrading/" target="_blank" class="del-underline" aria-label="Linkedin">
                            <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0b270d74-2b67-43b6-2a9a-9e9af19a6d00/public" width="24" height="24"></v-img>
                        </a>
                    </div>
                    <div >
                        <a href="https://github.com/unTrading" target="_blank" class="del-underline" aria-label="Github">
                            <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/48737c90-42b0-4069-a44a-e3a4616fdb00/public" width="24" height="24"></v-img>
                        </a>
                    </div>
                    <div>
                        <a href="https://t.me/untrading" target="_blank" class="del-underline" aria-label="Telegram">
                            <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/88bd42d6-38b2-4775-ebdc-b5d28b927500/public" width="24" height="24"></v-img>
                        </a>
                    </div>
                    <div>
                        <a href="https://discord.gg/D5rZdhzVWh" target="_blank" class="del-underline" aria-label="Discord">
                            <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ac803eba-9354-40bd-3de0-1c6ab1cc6d00/public" width="24" height="24"></v-img>
                        </a>
                    </div>
                    
                </v-col>
                <v-col cols="11" class="mt-8">
                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/570b4fc3-a9a5-4171-a286-c39ea70eb300/public" cover width="120" v-show="this.darkTheme == 0"></v-img>
                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/b74fc7e2-76e6-4886-dab5-4a915d291900/public" cover width="120" v-show="this.darkTheme == 1"></v-img>
                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ad711f74-e320-42e4-a346-1473c1566f00/public" cover width="120" v-show="this.darkTheme == 0"></v-img>
                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/8e64e336-d5ef-4fc4-48a7-3fa7eb9f8c00/public" cover width="120" v-show="this.darkTheme == 1"></v-img>
                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/56abb3aa-9120-40b3-efdf-34300a8e1700/public" cover width="120" v-show="this.darkTheme == 0"></v-img>
                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/f2fd4477-500d-4a8e-a78c-a6b69610ab00/public" cover width="120" v-show="this.darkTheme == 1"></v-img>
                </v-col>
                
                <v-col cols="11" class="mt-8">
                    <h2 class="body-p-medium text-primaryGrey">
                        About untrading.org
                    </h2>
                    <div class="body-p text-primaryGrey mt-6">
                        Welcome to untrading.org, the future of crypto trading! 
                    </div>
                    <div class="mt-6 body-p text-primaryGrey">
                        We're here to revolutionize the industry with our innovative approach and advanced technologies. Our platform is powered by ERC-5173, a groundbreaking protocol that eliminates conflicts of interest and fosters a shared wealth creation ecosystem. With our on-chain payments and licenses, you can enjoy seamless and secure transactions. We've also introduced a truly divisible NFT framework, built on an upgradable and multisignature contract protocol, giving you unparalleled flexibility and control over your assets. 
                    </div>
                    <div class="mt-6 body-p text-primaryGrey">
                        Join us at untrading.org and embark on a new era of trading, where innovation and opportunity intersect. Experience the future today and unleash the full potential of your crypto investments with untrading.org.
                    </div>
                </v-col>
                <v-col cols="11" class="mt-8 gradient-underline-hover body-p">
                    <div>
                        <router-link to="/" class="text-primaryGrey gradient-text-hover del-underline">
                            Home
                        </router-link>
                    </div>
                    <div class="mt-6">
                        <router-link to="/uncryptos" class="text-primaryGrey gradient-text-hover del-underline">
                            Cryptos
                        </router-link>
                    </div>
                    <div class="mt-6">
                        <router-link to="/unnfts" class="text-primaryGrey gradient-text-hover del-underline">
                            NFTs
                        </router-link>
                    </div>
                    <div class="mt-6">
                        <a href="https://eips.ethereum.org/EIPS/eip-5173" target="_blank" class="text-primaryGrey gradient-text-hover del-underline">
                            ERC-5173
                        </a>
                    </div>
                    <div class="mt-6">
                        <a href="https://docs.untrading.org/whitepaper" target="_blank" class="text-primaryGrey gradient-text-hover del-underline">
                            Whitepaper
                        </a>
                    </div>
                     <div class="mt-6">
                        <a href="https://docs.untrading.org/faqs/general" target="_blank" class="text-primaryGrey gradient-text-hover del-underline">
                            FAQ
                        </a>
                    </div>
                    <div class="mt-6">
                        <a href="https://docs.untrading.org/tutorials" target="_blank" class="text-primaryGrey gradient-text-hover del-underline">
                            Tutorials
                        </a>
                    </div>
                    <div class="mt-6">
                        <a href="https://docs.untrading.org/risks" target="_blank" class="text-primaryGrey del-underline">
                            <span class="gradient-text-hover">Risks</span>
                        </a>
                    </div>
                    <div class="mt-6">
                        <a href="https://docs.untrading.org/community-guidelines" target="_blank" class="text-primaryGrey del-underline">
                            <span class="gradient-text-hover">Community Guidelines</span>
                        </a>
                    </div>
                    <div class="mt-6">
                        <a href="https://docs.untrading.org/privacy-policy" target="_blank" class="text-primaryGrey gradient-text-hover del-underline">
                            Privacy Policy
                        </a>
                    </div>
                    <div class="mt-6">
                        <a href="https://docs.untrading.org/terms-of-service" target="_blank" class="text-primaryGrey gradient-text-hover del-underline">
                            Terms of Service
                        </a>
                    </div>
                    <div class="mt-6">
                        <a :href="$tools.getTestnetAppUrl(darkTheme, locale)" class="text-primaryGrey gradient-text-hover del-underline">
                            Testnet
                        </a>
                    </div>
                </v-col>
                <v-col cols="11" class="mt-4">
                    <span>
                        <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank" class="del-underline pointer">
                        <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/8554187d-f5dc-41f7-eb9e-637396d9d100/public" cover class="mb-3" width="80" alt="" />
                        </a>
                    </span>
                    <div class="body-p text-primaryGrey">
                        Except where otherwise noted, content on this site is licensed under a Creative Commons Attribution 4.0 International license.
                    </div>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<script>
import Media from '@/components/common/Media';
import Logo from '@/components/common/Logo';
import { mapGetters } from "vuex";
export default {
    data(){
        return {

        }
    },
    components: { Logo,Media },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme", "locale", "responsive"])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped lang="scss">
.pl-90 {
  padding-left: 90px !important;
}
</style>