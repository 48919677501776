<template>
    <div class="layoutContent">
        <h2 class="typography_h1 lexend-deca text-quaternary text-center">
            Partners
        </h2>
        <div class="Spacer_root__uoSvA" style="--height:32px"></div>
        <a class="hide-tablet page_customersLink link_root" rel="noopener" href="">
            <div class="page_logos logos_logoGrid">
                <div class="logos_logoGridItem">
                    <div class="logos_logoItem">
                        <a href="https://www.ibcgroup.io/" class="hide-tablet page_customersLink link_root" rel="noopener" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 340.2 136.1" fill="currentColor" class="icon_logotype text-quaternary" style="--Logotype-width: 180px;">
                                <!-- Generator: Adobe Illustrator 28.7.2, SVG Export Plug-In . SVG Version: 1.2.0 Build 154)  -->
                                <g>
                                <g id="_图层_1" data-name="图层_1" focusable="false">
                                    <g>
                                    <path d="M132,62.5h7.6c.8,0,1.4-.6,1.4-1.4v-7.6c0-.8-.6-1.4-1.4-1.4h-7c-.9,0-1.5-.7-1.5-1.5v-7.4c0-.7-.5-1.4-1.2-1.5h-19.5c-.4,0-.8.2-1.1.4l-9.4,9.4c-.3.3-.4.7-.4,1.1v8.5c0,.4,0,.7-.4,1h0c-.3.3-.6.4-1,.4h-7.7c-.4,0-.7,0-1-.4h0c-.2-.3-.4-.6-.4-1v-8.5c0-.4-.2-.8-.4-1.1l-9.6-9.4c-.3-.3-.7-.4-1.1-.4h-18.8c-.8,0-1.5.7-1.5,1.5v7.5c0,.4,0,.7-.4,1h0c-.3.3-.6.4-1,.4h-7.6c-.4,0-.7,0-1-.4h0c-.3-.3-.4-.6-.4-1h0v-7.6c0-.8-.6-1.4-1.4-1.4h-7.6c-.8,0-1.4.6-1.4,1.4v7.6h0c0,.4-.1.7-.4,1h0c-.3.3-.6.4-1,.4h-7.6c-.8,0-1.4.6-1.4,1.4v38.9c0,.8.6,1.4,1.4,1.4h7.6c.8,0,1.4-.6,1.4-1.4v-38.9c0-.4.1-.7.4-1h0c.3-.3.6-.4,1-.4h7.6c.4,0,.7,0,1,.4h0c.3.3.4.6.4,1v38.9c0,.8.6,1.4,1.4,1.4h29.3c.4,0,.8-.2,1.1-.4l9.4-9.4c.3-.3.4-.7.4-1.1v-8.5c0-.4,0-.7.4-1h0c.2-.3.6-.4,1-.4h7.7c.4,0,.7,0,1,.4h0c.3.3.4.6.4,1v8.4c0,.4.2.8.4,1.1l9.5,9.5c.3.3.7.4,1.1.4h29.2c.8,0,1.5-.7,1.5-1.5v-7.4c0-.8-.7-1.5-1.5-1.5h-28.2c-.8,0-1.5-.7-1.5-1.5v-7.4c0-.8-.7-1.5-1.5-1.5h-7.5c-.4,0-.7,0-1-.4h0c-.2-.3-.4-.6-.4-1v-7.6c0-.4,0-.7.4-1s.6-.4,1-.4h7.5c.8,0,1.5-.7,1.5-1.5v-7.4c0-.8.7-1.5,1.5-1.5h17.9c.4,0,.7,0,1,.4.3.3.4.6.4,1v7.6c0,.7.6,1.3,1.4,1.3ZM78.5,81.8c0,.8-.7,1.5-1.5,1.5h-17.9c-.8,0-1.5-.7-1.5-1.5v-7.4c0-.8.7-1.5,1.5-1.5h17.9c.4,0,.7,0,1,.4h0c.3.3.4.6.4,1v7.5h0ZM78.5,61.1c0,.4,0,.7-.4,1h0c-.3.3-.6.4-1,.4h-18c-.8,0-1.5-.7-1.5-1.5v-7.5c0-.4,0-.7.4-1,.3-.3.6-.4,1-.4h18c.8,0,1.5.7,1.5,1.5v7.5ZM88.9,71.5c0,.4,0,.7-.4,1h0c-.3.3-.6.4-1,.4h-7.6c-.4,0-.7,0-1-.4h0c-.3-.3-.4-.6-.4-1v-7.6c0-.4,0-.7.4-1h0c.3-.3.6-.4,1-.4h7.6c.4,0,.7,0,1,.4h0c.3.3.4.6.4,1v7.6Z"/>
                                    <g>
                                        <path d="M171.5,53.8c0,.3-.2.5-.4.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7Z"/>
                                        <path d="M182.5,49.5v-7.4c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7c0,.3-.2.5-.4.5h-2.3c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h2.3c.4,0,.5.2.6.5l3.7,7.4s.2,0,.2,0Z"/>
                                        <path d="M193.8,53.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-9.7h-2.9c-.3,0-.5-.2-.5-.5v-1.5c0-.3.2-.5.5-.5h8.5c.3,0,.4.2.4.5v1.5c0,.3-.2.5-.4.5h-2.9v9.7h0Z"/>
                                        <path d="M202.5,51.8h5.8c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h7.8c.3,0,.4.2.4.5v1.5c0,.3-.2.5-.4.5h-5.6v2.5h4.2c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-4.2v2.7Z"/>
                                        <path d="M219.3,50.1l1.8,3.7c.1.3-.1.5-.3.5h-1.9c-.4,0-.5-.2-.7-.5l-1.6-3.5h-2.3v3.5c0,.3-.2.5-.5.5h-1.8c-.3,0-.4-.2-.4-.5v-11.7c0-.3.2-.5.4-.5h6.2c1.9,0,2.8.9,2.8,2.8v3.1c0,2.4-1.7,2.6-1.7,2.6ZM214.3,44.1v3.8h3.2c.5,0,.9-.4.9-.9v-2c0-.5-.4-.9-.9-.9h-3.2Z"/>
                                        <path d="M231.7,49.5v-7.4c0-.3.2-.5.4-.5h1.8c.3,0,.5.2.5.5v11.7c0,.3-.2.5-.5.5h-2.2c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.7c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h2.3c.4,0,.5.2.6.5l3.7,7.4s0,0,0,0Z"/>
                                        <path d="M240.4,51.7l-.6,2.2c-.1.3-.3.5-.6.5h-2c-.2,0-.4-.2-.3-.5l3.8-11.7c.1-.3.3-.5.6-.5h2.2c.4,0,.5.2.6.5l3.8,11.7c.1.3-.1.5-.3.5h-2c-.4,0-.5-.2-.6-.5l-.6-2.2h-4ZM243.6,49.2l-1.2-4h-.2l-1.2,4h2.6Z"/>
                                        <path d="M253.4,53.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-9.7h-2.9c-.3,0-.4-.2-.4-.5v-1.5c0-.3.2-.5.4-.5h8.5c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-2.9v9.7h0Z"/>
                                        <path d="M262,53.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v11.7Z"/>
                                        <path d="M275.6,51.7c0,1.9-.9,2.8-2.8,2.8h-4.4c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.4c1.9,0,2.8.9,2.8,2.8v7.4ZM272.9,44.8c0-.5-.4-.9-.9-.9h-2.8c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.8c.5,0,.9-.4.9-.9v-6.3Z"/>
                                        <path d="M286.4,49.5v-7.4c0-.3.2-.5.4-.5h1.8c.3,0,.5.2.5.5v11.7c0,.3-.2.5-.5.5h-2.2c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h2.3c.4,0,.5.2.6.5l3.7,7.4s.2,0,.2,0Z"/>
                                        <path d="M295.1,51.7l-.6,2.2c-.1.3-.3.5-.6.5h-2c-.2,0-.4-.2-.3-.5l3.8-11.7c.1-.3.3-.5.6-.5h2.2c.4,0,.5.2.6.5l3.8,11.7c.1.3-.1.5-.3.5h-2c-.4,0-.5-.2-.6-.5l-.6-2.2h-4ZM298.4,49.2l-1.2-4h-.2l-1.2,4h2.6Z"/>
                                        <path d="M307.8,51.8h5.4c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-7.7c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v9.7h0Z"/>
                                        <path d="M168.8,62.1c0-.3.2-.5.5-.5h5.6c1.9,0,2.8.9,2.8,2.8v1.2c0,1.5-1.2,1.8-1.2,1.8v.2s1.8.3,1.8,2.2v1.7c0,1.9-.9,2.8-2.8,2.8h-6.2c-.3,0-.5-.2-.5-.5v-11.7h0ZM171.5,64.1v2.4h2.5c.5,0,.9-.4.9-.9v-.6c0-.5-.4-.9-.9-.9h-2.5ZM174.7,71.8c.5,0,.9-.4.9-.9v-1.2c0-.5-.4-.9-.9-.9h-3.2v3s3.2,0,3.2,0Z"/>
                                        <path d="M184.2,71.8h5.4c.3,0,.5.2.5.4v1.5c0,.3-.2.5-.5.5h-7.7c-.3,0-.5-.2-.5-.5v-11.6c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v9.7Z"/>
                                        <path d="M201.9,71.7c0,1.9-.9,2.8-2.8,2.8h-4.4c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.4c1.9,0,2.8.9,2.8,2.8v7.4ZM199.2,64.8c0-.5-.4-.9-.9-.9h-2.8c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.8c.5,0,.9-.4.9-.9v-6.3Z"/>
                                        <path d="M215.2,65.5c0,.3-.2.4-.5.4h-1.8c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-2.5c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.6c.5,0,.9-.4.9-.9v-.6c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v1.2c0,1.9-.9,2.8-2.8,2.8h-4.2c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.2c1.9,0,2.8.9,2.8,2.8v1.1h-.1Z"/>
                                        <path d="M221.2,66.4h1.7l2-4.3c.1-.3.3-.5.6-.5h2c.2,0,.4.2.3.5l-2.5,5.5,2.9,6.2c.1.3-.1.5-.4.5h-1.8c-.4,0-.6-.2-.7-.5l-2.3-5h-1.8v5c0,.3-.2.5-.5.5h-1.7c-.3,0-.4-.2-.4-.5v-11.7c0-.3.2-.5.4-.5h1.8c.3,0,.5.2.5.5v4.3h-.1Z"/>
                                        <path d="M240,65.5c0,.3-.2.4-.4.4h-1.8c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-2.6c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.6c.5,0,.9-.4.9-.9v-.6c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v1.2c0,1.9-.9,2.8-2.8,2.8h-4.2c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.2c1.9,0,2.8.9,2.8,2.8v1.1Z"/>
                                        <path d="M246,66.8h4.2v-4.7c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7c0,.3-.2.5-.4.5h-1.8c-.3,0-.5-.2-.5-.5v-4.6h-4.2v4.6c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v4.7Z"/>
                                        <path d="M258.9,71.7l-.6,2.2c-.1.3-.3.5-.6.5h-2c-.2,0-.4-.2-.3-.5l3.8-11.7c.1-.3.3-.5.6-.5h2.2c.4,0,.5.2.6.5l3.8,11.7c.1.3-.1.5-.3.5h-2c-.4,0-.5-.2-.6-.5l-.6-2.2h-4ZM262.2,69.2l-1.2-4h-.2l-1.2,4h2.6Z"/>
                                        <path d="M271.6,73.8c0,.3-.2.5-.4.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7Z"/>
                                        <path d="M282.6,69.5v-7.4c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7c0,.3-.2.5-.4.5h-2.3c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h2.3c.4,0,.5.2.6.5l3.7,7.4s.2,0,.2,0Z"/>
                                        <path d="M178.4,85.5c0,.3-.2.4-.5.4h-1.8c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-2.6c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.6c.5,0,.9-.4.9-.9v-.6c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v1.2c0,1.9-.9,2.8-2.8,2.8h-4.2c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.2c1.9,0,2.8.9,2.8,2.8v1.1Z"/>
                                        <path d="M191.6,91.7c0,1.9-.9,2.8-2.8,2.8h-4.4c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.4c1.9,0,2.8.9,2.8,2.8v7.4ZM188.9,84.8c0-.5-.4-.9-.9-.9h-2.8c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.8c.5,0,.9-.4.9-.9v-6.3Z"/>
                                        <path d="M202.4,89.5v-7.4c0-.3.2-.4.5-.4h1.8c.3,0,.4.2.4.4v11.7c0,.3-.2.5-.4.5h-2.3c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.4.5-.4h2.3c.4,0,.5.2.6.4l3.7,7.4h.2Z"/>
                                        <path d="M215.1,90.6c0-.5-.2-.6-.6-.8l-4-1.5c-1.3-.5-2-1.2-2-2.7v-1.3c0-1.9.9-2.8,2.8-2.8h3.5c1.9,0,2.8.9,2.8,2.8v1.2c0,.3-.2.4-.5.4h-1.8c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-1.9c-.5,0-.9.4-.9.9v.5c0,.5.2.6.6.8l4,1.5c1.3.5,2,1.2,2,2.7v1.3c0,1.9-.9,2.8-2.8,2.8h-3.9c-1.9,0-2.8-.9-2.8-2.8v-1.2c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v.6c0,.5.4.9.9.9h2.2c.5,0,.9-.4.9-.9v-.5h0Z"/>
                                        <path d="M221,82.1c0-.3.2-.4.4-.4h1.8c.3,0,.5.2.5.4v9c0,.5.4.9.9.9h2.4c.5,0,.9-.4.9-.9v-9c0-.3.2-.4.4-.4h1.8c.3,0,.5.2.5.4v9.5c0,1.9-.9,2.8-2.8,2.8h-4.1c-1.9,0-2.8-.9-2.8-2.8v-9.5h.1Z"/>
                                        <path d="M237.1,91.9h5.4c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-7.7c-.3,0-.5-.2-.5-.5v-11.8c0-.3.2-.4.5-.4h1.8c.3,0,.5.2.5.4v9.8Z"/>
                                        <path d="M248.5,93.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-9.7h-2.9c-.3,0-.4-.2-.4-.4v-1.5c0-.3.2-.4.4-.4h8.5c.3,0,.5.2.5.4v1.5c0,.3-.2.4-.5.4h-2.9v9.7h0Z"/>
                                        <path d="M257.1,93.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.4.5-.4h1.8c.3,0,.5.2.5.4v11.7Z"/>
                                        <path d="M268.1,89.5v-7.4c0-.3.2-.4.5-.4h1.8c.3,0,.5.2.5.4v11.7c0,.3-.2.5-.5.5h-2.3c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.4-.2-.4-.5v-11.7c0-.3.2-.4.4-.4h2.3c.4,0,.5.2.6.4l3.7,7.4h.2Z"/>
                                        <path d="M284.2,91.7c0,1.9-.9,2.8-2.8,2.8h-4.2c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.2c1.9,0,2.8.9,2.8,2.8v1.2c0,.3-.2.4-.5.4h-1.7c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-2.6c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.6c.5,0,.9-.4.9-.9v-1.4h-1.7c-.3,0-.5-.2-.5-.5v-1.5c0-.3.2-.5.5-.5h4c.3,0,.5.2.5.5v3.9h0Z"/>
                                    </g>
                                    </g>
                                </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div class="logos_logoGridItem">
                    <div class="logos_logoItem">
                        <a href="https://www.falconx.io/" class="hide-tablet page_customersLink link_root" rel="noopener" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 340.2 136.1" fill="currentColor" class="icon_logotype text-quaternary" style="--Logotype-width: 180px;">
                                <!-- Generator: Adobe Illustrator 28.7.2, SVG Export Plug-In . SVG Version: 1.2.0 Build 154)  -->
                                <g>
                                <g id="_图层_1" data-name="图层_1" focusable="false">
                                    <g>
                                    <path class="cls-1" d="M276.4,71.2l-3.2,4.6,3.8,5.5h6.5l-7.1-10ZM277,54.8l-3.8,5.5,3.2,4.5,7.1-10h-6.4ZM264.7,54.8h-6.5l9.4,13.4-9.1,13h6.5l9-13-9.3-13.4Z"/>
                                    <path class="cls-1" d="M56.7,65.8v15.6h5.5v-10c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h7.7c.8,0,1.4-.4,1.8-1.3l1.2-3.3h-17.1ZM56.7,54.8v4.8h19.2c.8,0,1.4-.4,1.8-1.2l1.4-3.6h-22.4Z"/>
                                    <path class="cls-1" d="M98.8,56.7c-.6-1.3-1.5-1.9-2.8-1.9h-4.3l-10.9,26.6h5.7l8.3-21.2,8.1,21.2h6l-10.1-24.7Z"/>
                                    <path class="cls-1" d="M125.3,76.5v-19.8c0-.5-.2-1-.5-1.4-.4-.4-.8-.5-1.3-.5h-3.6v26.6h16.9c.8,0,1.3-.4,1.7-1.2l1.4-3.7h-14.6Z"/>
                                    <path class="cls-1" d="M168.7,74.5c-.4,0-.8.1-1.1.4-1.6,1.2-3.5,1.9-5.6,1.9s-4.6-.8-6.2-2.5c-1.6-1.7-2.5-3.7-2.5-6.1s.8-4.6,2.4-6.2c1.6-1.6,3.7-2.5,6.1-2.5s4,.7,5.7,2c.4.3.8.4,1.1.4s.7-.1,1.1-.3l3.1-2.1c-1.2-1.5-2.8-2.7-4.8-3.6-2-.9-4-1.4-6.3-1.4s-5,.6-7.2,1.8c-2.2,1.2-3.9,2.8-5.1,4.9s-1.9,4.4-1.9,7,.6,4.7,1.8,6.8c1.2,2.1,2.9,3.7,5,5,2.1,1.3,4.6,1.9,7.4,1.9s4.1-.4,6.1-1.3,3.5-2.1,4.8-3.6l-2.9-2.1c-.4-.2-.7-.3-1.1-.3Z"/>
                                    <path class="cls-1" d="M246.2,54.8h-5.2v19.1c-2.7-3.9-8.7-12.7-11.5-16.8-1-1.4-2.6-2.3-4.3-2.3h-2.6v26.6h5.2v-17.8l10.2,15.4c1,1.5,2.6,2.3,4.3,2.3h3.9v-26.6h0Z"/>
                                    <path class="cls-1" d="M183.5,60.9c-1.2,2.1-1.8,4.4-1.8,6.9s.6,5.1,2,7.1c1.3,2.1,3,3.7,5.2,4.8,1.5.8,3,1.3,4.6,1.6v-5.2c-1.4-.4-2.6-1.1-3.7-2.2-1.6-1.7-2.4-3.7-2.4-6.2s.8-4.6,2.4-6.2c1.1-1.1,2.3-1.8,3.7-2.2v-5.2c-1.8.3-3.4.8-4.9,1.7-2.1,1.2-3.8,2.9-5,5ZM207.8,60.7c-1.3-2.1-3-3.7-5.1-4.8-1.5-.8-3-1.3-4.7-1.6v5.2c1.4.4,2.7,1.1,3.7,2.2,1.6,1.7,2.4,3.7,2.4,6.2s-.8,4.5-2.4,6.2c-1.1,1.1-2.3,1.8-3.7,2.2v5.2c1.8-.3,3.4-.8,4.9-1.7,2.1-1.2,3.8-2.9,5-5,1.2-2.1,1.8-4.4,1.8-6.9,0-2.7-.6-5-1.9-7.1Z"/>
                                    </g>
                                </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
                <div class="logos_logoGridItem">
                   <div class="logos_logoItem">
                        <a href="https://app.daomaker.com/" class="hide-tablet page_customersLink link_root" rel="noopener" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 340.2 136.1" fill="currentColor" class="icon_logotype text-quaternary" style="--Logotype-width: 180px;">
                                <!-- Generator: Adobe Illustrator 28.7.2, SVG Export Plug-In . SVG Version: 1.2.0 Build 154)  -->
                                <g>
                                <g id="_图层_1" data-name="图层_1" focusable="false">
                                    <g>
                                    <path d="M142.9,82.4h-7.9v-22.1h8c2.2,0,4.2.4,5.8,1.3s2.9,2.1,3.7,3.8c.9,1.7,1.3,3.6,1.3,5.9s-.4,4.3-1.3,5.9c-.9,1.7-2.1,2.9-3.8,3.8-1.6.9-3.5,1.4-5.8,1.4ZM139.7,78.3h3c2.1,0,3.7-.5,4.8-1.7,1.1-1.1,1.6-2.9,1.6-5.4s-.5-4.2-1.6-5.3-2.7-1.7-4.8-1.7h-3v14.1h0Z"/>
                                    <path d="M157.3,82.4h-5l7.7-22.1h6.1l7.7,22.1h-5l-1.7-5h-8.1l-1.7,5ZM160.2,73.7h5.7l-2.7-8.4h-.2l-2.8,8.4Z"/>
                                    <path d="M193.1,71.3c0,2.4-.5,4.5-1.4,6.2s-2.2,3-3.7,3.9c-1.6.9-3.3,1.4-5.3,1.4s-3.8-.4-5.3-1.4c-1.6-.9-2.8-2.2-3.7-3.9-.9-1.7-1.4-3.7-1.4-6.1s.5-4.5,1.4-6.2,2.1-3,3.7-3.9c1.6-.9,3.3-1.4,5.3-1.4s3.7.4,5.3,1.4c1.6.9,2.8,2.2,3.7,3.9,1,1.7,1.4,3.7,1.4,6.1ZM188.4,71.3c0-2.3-.5-4.1-1.6-5.3s-2.4-1.8-4.1-1.8-3.1.6-4.1,1.8c-1,1.3-1.6,3-1.6,5.3s.5,4.1,1.6,5.3,2.4,1.8,4.1,1.8,3.1-.6,4.1-1.8c1-1.2,1.6-3,1.6-5.3Z"/>
                                    <path d="M199.5,60.2h5.8l6.2,14.9h.2l6.2-14.9h5.8v22.1h-4.6v-14.4h-.2l-5.8,14.3h-3.1l-5.8-14.3h-.2v14.5h-4.6v-22.1h0Z"/>
                                    <path d="M230.5,82.7c-1.6,0-2.9-.4-4-1.3-1.1-.8-1.6-2.1-1.6-3.7s.3-2.2.9-2.9c.6-.7,1.4-1.2,2.3-1.6.9-.3,2-.5,3.1-.6,1.5-.1,2.5-.3,3.1-.5.6-.2.9-.5.9-1.1h0c0-.7-.2-1.3-.6-1.6-.4-.4-1-.5-1.8-.5s-1.5.2-2,.5c-.5.3-.8.8-1,1.4l-4.3-.3c.3-1.5,1.1-2.7,2.4-3.6,1.3-.9,2.9-1.4,4.9-1.4s2.4.2,3.5.6,1.9,1,2.6,1.8,1,1.9,1,3.2v11.2h-4.4v-2.3h0c-.4.8-1,1.4-1.8,1.9-.8.5-1.8.7-3.1.7ZM231.9,79.5c1,0,1.8-.3,2.5-.9.6-.6,1-1.3,1-2.2v-1.7c-.2,0-.5.2-.9.3s-.8.2-1.2.2-.8,0-1.2.2c-.8,0-1.5.3-2,.7-.5.3-.7.8-.7,1.5s.2,1.1.7,1.4c.4.2,1.1.4,1.7.4Z"/>
                                    <path d="M241.8,82.4v-22.1h4.7v11.8h.2l5.3-6.3h5.3l-6.2,7.2,6.6,9.4h-5.4l-4.6-6.7-1.3,1.4v5.3h-4.6Z"/>
                                    <path d="M264.4,82.7c-1.7,0-3.2-.3-4.5-1.1-1.2-.7-2.2-1.7-2.9-3-.7-1.3-1-2.8-1-4.6s.3-3.2,1-4.5c.7-1.3,1.6-2.3,2.8-3s2.7-1.1,4.3-1.1,2.8.3,4,.9c1.2.6,2.1,1.6,2.8,2.8.7,1.3,1.1,2.8,1.1,4.7v1.3h-11.4c0,1.2.3,2.2,1,2.9s1.6,1.1,2.8,1.1,1.5-.2,2-.5c.6-.3,1-.8,1.2-1.4l4.3.3c-.3,1.6-1.2,2.8-2.5,3.7-1.3.9-3,1.4-5.1,1.4ZM260.7,72.4h7c0-1-.3-1.8-1-2.4s-1.5-1-2.5-1-1.9.3-2.5,1c-.7.7-1,1.5-1.1,2.4Z"/>
                                    <path d="M273.3,82.4v-16.6h4.5v2.9h.2c.3-1,.8-1.8,1.6-2.3s1.6-.8,2.5-.8,1,0,1.5.2v4.1c-.2,0-.5,0-.9-.2-.4,0-.7,0-1.1,0-1,0-1.9.3-2.5,1-.7.6-1,1.5-1,2.5v9.4h-4.7Z"/>
                                    <path d="M56.7,93l22.3-41.3,19.7-8.7-22.3,41.3-19.7,8.7Z"/>
                                    <path d="M79,83.5l16.9-31.4,9.6,9.5-16.7,31.5-9.9-9.5Z"/>
                                    <path d="M107.4,62.6l12.5,4.6-6.3,11.5-15.2.5,8.9-16.6Z"/>
                                    </g>
                                </g>
                                </g>
                            </svg>
                        </a>
                   </div>
                </div>
            </div>
        </a>
        <a class="show-tablet Link_root__cNtak" rel="noopener" href="#">
            <div class="bleed_root">
                <div class="Marquee_root__pXJQM flex_root" style="--Marquee-shadow-size:16px;--Marquee-duration:20s;--flex-direction:row">
                    <div class="Marquee_content__n0Vyj">
                        <a href="https://www.ibcgroup.io/" class="show-tablet page_customersLink link_root" rel="noopener" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 340.2 136.1" fill="currentColor" class="icon_logotype text-quaternary" style="--Logotype-width: 180px;">
                                <!-- Generator: Adobe Illustrator 28.7.2, SVG Export Plug-In . SVG Version: 1.2.0 Build 154)  -->
                                <g>
                                <g id="_图层_1" data-name="图层_1" focusable="false">
                                    <g>
                                    <path d="M132,62.5h7.6c.8,0,1.4-.6,1.4-1.4v-7.6c0-.8-.6-1.4-1.4-1.4h-7c-.9,0-1.5-.7-1.5-1.5v-7.4c0-.7-.5-1.4-1.2-1.5h-19.5c-.4,0-.8.2-1.1.4l-9.4,9.4c-.3.3-.4.7-.4,1.1v8.5c0,.4,0,.7-.4,1h0c-.3.3-.6.4-1,.4h-7.7c-.4,0-.7,0-1-.4h0c-.2-.3-.4-.6-.4-1v-8.5c0-.4-.2-.8-.4-1.1l-9.6-9.4c-.3-.3-.7-.4-1.1-.4h-18.8c-.8,0-1.5.7-1.5,1.5v7.5c0,.4,0,.7-.4,1h0c-.3.3-.6.4-1,.4h-7.6c-.4,0-.7,0-1-.4h0c-.3-.3-.4-.6-.4-1h0v-7.6c0-.8-.6-1.4-1.4-1.4h-7.6c-.8,0-1.4.6-1.4,1.4v7.6h0c0,.4-.1.7-.4,1h0c-.3.3-.6.4-1,.4h-7.6c-.8,0-1.4.6-1.4,1.4v38.9c0,.8.6,1.4,1.4,1.4h7.6c.8,0,1.4-.6,1.4-1.4v-38.9c0-.4.1-.7.4-1h0c.3-.3.6-.4,1-.4h7.6c.4,0,.7,0,1,.4h0c.3.3.4.6.4,1v38.9c0,.8.6,1.4,1.4,1.4h29.3c.4,0,.8-.2,1.1-.4l9.4-9.4c.3-.3.4-.7.4-1.1v-8.5c0-.4,0-.7.4-1h0c.2-.3.6-.4,1-.4h7.7c.4,0,.7,0,1,.4h0c.3.3.4.6.4,1v8.4c0,.4.2.8.4,1.1l9.5,9.5c.3.3.7.4,1.1.4h29.2c.8,0,1.5-.7,1.5-1.5v-7.4c0-.8-.7-1.5-1.5-1.5h-28.2c-.8,0-1.5-.7-1.5-1.5v-7.4c0-.8-.7-1.5-1.5-1.5h-7.5c-.4,0-.7,0-1-.4h0c-.2-.3-.4-.6-.4-1v-7.6c0-.4,0-.7.4-1s.6-.4,1-.4h7.5c.8,0,1.5-.7,1.5-1.5v-7.4c0-.8.7-1.5,1.5-1.5h17.9c.4,0,.7,0,1,.4.3.3.4.6.4,1v7.6c0,.7.6,1.3,1.4,1.3ZM78.5,81.8c0,.8-.7,1.5-1.5,1.5h-17.9c-.8,0-1.5-.7-1.5-1.5v-7.4c0-.8.7-1.5,1.5-1.5h17.9c.4,0,.7,0,1,.4h0c.3.3.4.6.4,1v7.5h0ZM78.5,61.1c0,.4,0,.7-.4,1h0c-.3.3-.6.4-1,.4h-18c-.8,0-1.5-.7-1.5-1.5v-7.5c0-.4,0-.7.4-1,.3-.3.6-.4,1-.4h18c.8,0,1.5.7,1.5,1.5v7.5ZM88.9,71.5c0,.4,0,.7-.4,1h0c-.3.3-.6.4-1,.4h-7.6c-.4,0-.7,0-1-.4h0c-.3-.3-.4-.6-.4-1v-7.6c0-.4,0-.7.4-1h0c.3-.3.6-.4,1-.4h7.6c.4,0,.7,0,1,.4h0c.3.3.4.6.4,1v7.6Z"/>
                                    <g>
                                        <path d="M171.5,53.8c0,.3-.2.5-.4.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7Z"/>
                                        <path d="M182.5,49.5v-7.4c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7c0,.3-.2.5-.4.5h-2.3c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h2.3c.4,0,.5.2.6.5l3.7,7.4s.2,0,.2,0Z"/>
                                        <path d="M193.8,53.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-9.7h-2.9c-.3,0-.5-.2-.5-.5v-1.5c0-.3.2-.5.5-.5h8.5c.3,0,.4.2.4.5v1.5c0,.3-.2.5-.4.5h-2.9v9.7h0Z"/>
                                        <path d="M202.5,51.8h5.8c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h7.8c.3,0,.4.2.4.5v1.5c0,.3-.2.5-.4.5h-5.6v2.5h4.2c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-4.2v2.7Z"/>
                                        <path d="M219.3,50.1l1.8,3.7c.1.3-.1.5-.3.5h-1.9c-.4,0-.5-.2-.7-.5l-1.6-3.5h-2.3v3.5c0,.3-.2.5-.5.5h-1.8c-.3,0-.4-.2-.4-.5v-11.7c0-.3.2-.5.4-.5h6.2c1.9,0,2.8.9,2.8,2.8v3.1c0,2.4-1.7,2.6-1.7,2.6ZM214.3,44.1v3.8h3.2c.5,0,.9-.4.9-.9v-2c0-.5-.4-.9-.9-.9h-3.2Z"/>
                                        <path d="M231.7,49.5v-7.4c0-.3.2-.5.4-.5h1.8c.3,0,.5.2.5.5v11.7c0,.3-.2.5-.5.5h-2.2c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.7c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h2.3c.4,0,.5.2.6.5l3.7,7.4s0,0,0,0Z"/>
                                        <path d="M240.4,51.7l-.6,2.2c-.1.3-.3.5-.6.5h-2c-.2,0-.4-.2-.3-.5l3.8-11.7c.1-.3.3-.5.6-.5h2.2c.4,0,.5.2.6.5l3.8,11.7c.1.3-.1.5-.3.5h-2c-.4,0-.5-.2-.6-.5l-.6-2.2h-4ZM243.6,49.2l-1.2-4h-.2l-1.2,4h2.6Z"/>
                                        <path d="M253.4,53.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-9.7h-2.9c-.3,0-.4-.2-.4-.5v-1.5c0-.3.2-.5.4-.5h8.5c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-2.9v9.7h0Z"/>
                                        <path d="M262,53.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v11.7Z"/>
                                        <path d="M275.6,51.7c0,1.9-.9,2.8-2.8,2.8h-4.4c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.4c1.9,0,2.8.9,2.8,2.8v7.4ZM272.9,44.8c0-.5-.4-.9-.9-.9h-2.8c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.8c.5,0,.9-.4.9-.9v-6.3Z"/>
                                        <path d="M286.4,49.5v-7.4c0-.3.2-.5.4-.5h1.8c.3,0,.5.2.5.5v11.7c0,.3-.2.5-.5.5h-2.2c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h2.3c.4,0,.5.2.6.5l3.7,7.4s.2,0,.2,0Z"/>
                                        <path d="M295.1,51.7l-.6,2.2c-.1.3-.3.5-.6.5h-2c-.2,0-.4-.2-.3-.5l3.8-11.7c.1-.3.3-.5.6-.5h2.2c.4,0,.5.2.6.5l3.8,11.7c.1.3-.1.5-.3.5h-2c-.4,0-.5-.2-.6-.5l-.6-2.2h-4ZM298.4,49.2l-1.2-4h-.2l-1.2,4h2.6Z"/>
                                        <path d="M307.8,51.8h5.4c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-7.7c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v9.7h0Z"/>
                                        <path d="M168.8,62.1c0-.3.2-.5.5-.5h5.6c1.9,0,2.8.9,2.8,2.8v1.2c0,1.5-1.2,1.8-1.2,1.8v.2s1.8.3,1.8,2.2v1.7c0,1.9-.9,2.8-2.8,2.8h-6.2c-.3,0-.5-.2-.5-.5v-11.7h0ZM171.5,64.1v2.4h2.5c.5,0,.9-.4.9-.9v-.6c0-.5-.4-.9-.9-.9h-2.5ZM174.7,71.8c.5,0,.9-.4.9-.9v-1.2c0-.5-.4-.9-.9-.9h-3.2v3s3.2,0,3.2,0Z"/>
                                        <path d="M184.2,71.8h5.4c.3,0,.5.2.5.4v1.5c0,.3-.2.5-.5.5h-7.7c-.3,0-.5-.2-.5-.5v-11.6c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v9.7Z"/>
                                        <path d="M201.9,71.7c0,1.9-.9,2.8-2.8,2.8h-4.4c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.4c1.9,0,2.8.9,2.8,2.8v7.4ZM199.2,64.8c0-.5-.4-.9-.9-.9h-2.8c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.8c.5,0,.9-.4.9-.9v-6.3Z"/>
                                        <path d="M215.2,65.5c0,.3-.2.4-.5.4h-1.8c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-2.5c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.6c.5,0,.9-.4.9-.9v-.6c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v1.2c0,1.9-.9,2.8-2.8,2.8h-4.2c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.2c1.9,0,2.8.9,2.8,2.8v1.1h-.1Z"/>
                                        <path d="M221.2,66.4h1.7l2-4.3c.1-.3.3-.5.6-.5h2c.2,0,.4.2.3.5l-2.5,5.5,2.9,6.2c.1.3-.1.5-.4.5h-1.8c-.4,0-.6-.2-.7-.5l-2.3-5h-1.8v5c0,.3-.2.5-.5.5h-1.7c-.3,0-.4-.2-.4-.5v-11.7c0-.3.2-.5.4-.5h1.8c.3,0,.5.2.5.5v4.3h-.1Z"/>
                                        <path d="M240,65.5c0,.3-.2.4-.4.4h-1.8c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-2.6c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.6c.5,0,.9-.4.9-.9v-.6c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v1.2c0,1.9-.9,2.8-2.8,2.8h-4.2c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.2c1.9,0,2.8.9,2.8,2.8v1.1Z"/>
                                        <path d="M246,66.8h4.2v-4.7c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7c0,.3-.2.5-.4.5h-1.8c-.3,0-.5-.2-.5-.5v-4.6h-4.2v4.6c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v4.7Z"/>
                                        <path d="M258.9,71.7l-.6,2.2c-.1.3-.3.5-.6.5h-2c-.2,0-.4-.2-.3-.5l3.8-11.7c.1-.3.3-.5.6-.5h2.2c.4,0,.5.2.6.5l3.8,11.7c.1.3-.1.5-.3.5h-2c-.4,0-.5-.2-.6-.5l-.6-2.2h-4ZM262.2,69.2l-1.2-4h-.2l-1.2,4h2.6Z"/>
                                        <path d="M271.6,73.8c0,.3-.2.5-.4.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7Z"/>
                                        <path d="M282.6,69.5v-7.4c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7c0,.3-.2.5-.4.5h-2.3c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h2.3c.4,0,.5.2.6.5l3.7,7.4s.2,0,.2,0Z"/>
                                        <path d="M178.4,85.5c0,.3-.2.4-.5.4h-1.8c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-2.6c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.6c.5,0,.9-.4.9-.9v-.6c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v1.2c0,1.9-.9,2.8-2.8,2.8h-4.2c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.2c1.9,0,2.8.9,2.8,2.8v1.1Z"/>
                                        <path d="M191.6,91.7c0,1.9-.9,2.8-2.8,2.8h-4.4c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.4c1.9,0,2.8.9,2.8,2.8v7.4ZM188.9,84.8c0-.5-.4-.9-.9-.9h-2.8c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.8c.5,0,.9-.4.9-.9v-6.3Z"/>
                                        <path d="M202.4,89.5v-7.4c0-.3.2-.4.5-.4h1.8c.3,0,.4.2.4.4v11.7c0,.3-.2.5-.4.5h-2.3c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.4.5-.4h2.3c.4,0,.5.2.6.4l3.7,7.4h.2Z"/>
                                        <path d="M215.1,90.6c0-.5-.2-.6-.6-.8l-4-1.5c-1.3-.5-2-1.2-2-2.7v-1.3c0-1.9.9-2.8,2.8-2.8h3.5c1.9,0,2.8.9,2.8,2.8v1.2c0,.3-.2.4-.5.4h-1.8c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-1.9c-.5,0-.9.4-.9.9v.5c0,.5.2.6.6.8l4,1.5c1.3.5,2,1.2,2,2.7v1.3c0,1.9-.9,2.8-2.8,2.8h-3.9c-1.9,0-2.8-.9-2.8-2.8v-1.2c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v.6c0,.5.4.9.9.9h2.2c.5,0,.9-.4.9-.9v-.5h0Z"/>
                                        <path d="M221,82.1c0-.3.2-.4.4-.4h1.8c.3,0,.5.2.5.4v9c0,.5.4.9.9.9h2.4c.5,0,.9-.4.9-.9v-9c0-.3.2-.4.4-.4h1.8c.3,0,.5.2.5.4v9.5c0,1.9-.9,2.8-2.8,2.8h-4.1c-1.9,0-2.8-.9-2.8-2.8v-9.5h.1Z"/>
                                        <path d="M237.1,91.9h5.4c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-7.7c-.3,0-.5-.2-.5-.5v-11.8c0-.3.2-.4.5-.4h1.8c.3,0,.5.2.5.4v9.8Z"/>
                                        <path d="M248.5,93.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-9.7h-2.9c-.3,0-.4-.2-.4-.4v-1.5c0-.3.2-.4.4-.4h8.5c.3,0,.5.2.5.4v1.5c0,.3-.2.4-.5.4h-2.9v9.7h0Z"/>
                                        <path d="M257.1,93.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.4.5-.4h1.8c.3,0,.5.2.5.4v11.7Z"/>
                                        <path d="M268.1,89.5v-7.4c0-.3.2-.4.5-.4h1.8c.3,0,.5.2.5.4v11.7c0,.3-.2.5-.5.5h-2.3c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.4-.2-.4-.5v-11.7c0-.3.2-.4.4-.4h2.3c.4,0,.5.2.6.4l3.7,7.4h.2Z"/>
                                        <path d="M284.2,91.7c0,1.9-.9,2.8-2.8,2.8h-4.2c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.2c1.9,0,2.8.9,2.8,2.8v1.2c0,.3-.2.4-.5.4h-1.7c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-2.6c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.6c.5,0,.9-.4.9-.9v-1.4h-1.7c-.3,0-.5-.2-.5-.5v-1.5c0-.3.2-.5.5-.5h4c.3,0,.5.2.5.5v3.9h0Z"/>
                                    </g>
                                    </g>
                                </g>
                                </g>
                            </svg>
                        </a>
                        <a href="https://www.falconx.io/" class="page_customersLink link_root" rel="noopener" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 340.2 136.1" fill="currentColor" class="icon_logotype text-quaternary" style="--Logotype-width: 180px;">
                                <!-- Generator: Adobe Illustrator 28.7.2, SVG Export Plug-In . SVG Version: 1.2.0 Build 154)  -->
                                <g>
                                <g id="_图层_1" data-name="图层_1" focusable="false">
                                    <g>
                                    <path class="cls-1" d="M276.4,71.2l-3.2,4.6,3.8,5.5h6.5l-7.1-10ZM277,54.8l-3.8,5.5,3.2,4.5,7.1-10h-6.4ZM264.7,54.8h-6.5l9.4,13.4-9.1,13h6.5l9-13-9.3-13.4Z"/>
                                    <path class="cls-1" d="M56.7,65.8v15.6h5.5v-10c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h7.7c.8,0,1.4-.4,1.8-1.3l1.2-3.3h-17.1ZM56.7,54.8v4.8h19.2c.8,0,1.4-.4,1.8-1.2l1.4-3.6h-22.4Z"/>
                                    <path class="cls-1" d="M98.8,56.7c-.6-1.3-1.5-1.9-2.8-1.9h-4.3l-10.9,26.6h5.7l8.3-21.2,8.1,21.2h6l-10.1-24.7Z"/>
                                    <path class="cls-1" d="M125.3,76.5v-19.8c0-.5-.2-1-.5-1.4-.4-.4-.8-.5-1.3-.5h-3.6v26.6h16.9c.8,0,1.3-.4,1.7-1.2l1.4-3.7h-14.6Z"/>
                                    <path class="cls-1" d="M168.7,74.5c-.4,0-.8.1-1.1.4-1.6,1.2-3.5,1.9-5.6,1.9s-4.6-.8-6.2-2.5c-1.6-1.7-2.5-3.7-2.5-6.1s.8-4.6,2.4-6.2c1.6-1.6,3.7-2.5,6.1-2.5s4,.7,5.7,2c.4.3.8.4,1.1.4s.7-.1,1.1-.3l3.1-2.1c-1.2-1.5-2.8-2.7-4.8-3.6-2-.9-4-1.4-6.3-1.4s-5,.6-7.2,1.8c-2.2,1.2-3.9,2.8-5.1,4.9s-1.9,4.4-1.9,7,.6,4.7,1.8,6.8c1.2,2.1,2.9,3.7,5,5,2.1,1.3,4.6,1.9,7.4,1.9s4.1-.4,6.1-1.3,3.5-2.1,4.8-3.6l-2.9-2.1c-.4-.2-.7-.3-1.1-.3Z"/>
                                    <path class="cls-1" d="M246.2,54.8h-5.2v19.1c-2.7-3.9-8.7-12.7-11.5-16.8-1-1.4-2.6-2.3-4.3-2.3h-2.6v26.6h5.2v-17.8l10.2,15.4c1,1.5,2.6,2.3,4.3,2.3h3.9v-26.6h0Z"/>
                                    <path class="cls-1" d="M183.5,60.9c-1.2,2.1-1.8,4.4-1.8,6.9s.6,5.1,2,7.1c1.3,2.1,3,3.7,5.2,4.8,1.5.8,3,1.3,4.6,1.6v-5.2c-1.4-.4-2.6-1.1-3.7-2.2-1.6-1.7-2.4-3.7-2.4-6.2s.8-4.6,2.4-6.2c1.1-1.1,2.3-1.8,3.7-2.2v-5.2c-1.8.3-3.4.8-4.9,1.7-2.1,1.2-3.8,2.9-5,5ZM207.8,60.7c-1.3-2.1-3-3.7-5.1-4.8-1.5-.8-3-1.3-4.7-1.6v5.2c1.4.4,2.7,1.1,3.7,2.2,1.6,1.7,2.4,3.7,2.4,6.2s-.8,4.5-2.4,6.2c-1.1,1.1-2.3,1.8-3.7,2.2v5.2c1.8-.3,3.4-.8,4.9-1.7,2.1-1.2,3.8-2.9,5-5,1.2-2.1,1.8-4.4,1.8-6.9,0-2.7-.6-5-1.9-7.1Z"/>
                                    </g>
                                </g>
                                </g>
                            </svg>
                        </a>
                        <a href="https://app.daomaker.com/" class="page_customersLink link_root" rel="noopener" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 340.2 136.1" fill="currentColor" class="icon_logotype text-quaternary" style="--Logotype-width: 180px;">
                                <!-- Generator: Adobe Illustrator 28.7.2, SVG Export Plug-In . SVG Version: 1.2.0 Build 154)  -->
                                <g>
                                <g id="_图层_1" data-name="图层_1" focusable="false">
                                    <g>
                                    <path d="M142.9,82.4h-7.9v-22.1h8c2.2,0,4.2.4,5.8,1.3s2.9,2.1,3.7,3.8c.9,1.7,1.3,3.6,1.3,5.9s-.4,4.3-1.3,5.9c-.9,1.7-2.1,2.9-3.8,3.8-1.6.9-3.5,1.4-5.8,1.4ZM139.7,78.3h3c2.1,0,3.7-.5,4.8-1.7,1.1-1.1,1.6-2.9,1.6-5.4s-.5-4.2-1.6-5.3-2.7-1.7-4.8-1.7h-3v14.1h0Z"/>
                                    <path d="M157.3,82.4h-5l7.7-22.1h6.1l7.7,22.1h-5l-1.7-5h-8.1l-1.7,5ZM160.2,73.7h5.7l-2.7-8.4h-.2l-2.8,8.4Z"/>
                                    <path d="M193.1,71.3c0,2.4-.5,4.5-1.4,6.2s-2.2,3-3.7,3.9c-1.6.9-3.3,1.4-5.3,1.4s-3.8-.4-5.3-1.4c-1.6-.9-2.8-2.2-3.7-3.9-.9-1.7-1.4-3.7-1.4-6.1s.5-4.5,1.4-6.2,2.1-3,3.7-3.9c1.6-.9,3.3-1.4,5.3-1.4s3.7.4,5.3,1.4c1.6.9,2.8,2.2,3.7,3.9,1,1.7,1.4,3.7,1.4,6.1ZM188.4,71.3c0-2.3-.5-4.1-1.6-5.3s-2.4-1.8-4.1-1.8-3.1.6-4.1,1.8c-1,1.3-1.6,3-1.6,5.3s.5,4.1,1.6,5.3,2.4,1.8,4.1,1.8,3.1-.6,4.1-1.8c1-1.2,1.6-3,1.6-5.3Z"/>
                                    <path d="M199.5,60.2h5.8l6.2,14.9h.2l6.2-14.9h5.8v22.1h-4.6v-14.4h-.2l-5.8,14.3h-3.1l-5.8-14.3h-.2v14.5h-4.6v-22.1h0Z"/>
                                    <path d="M230.5,82.7c-1.6,0-2.9-.4-4-1.3-1.1-.8-1.6-2.1-1.6-3.7s.3-2.2.9-2.9c.6-.7,1.4-1.2,2.3-1.6.9-.3,2-.5,3.1-.6,1.5-.1,2.5-.3,3.1-.5.6-.2.9-.5.9-1.1h0c0-.7-.2-1.3-.6-1.6-.4-.4-1-.5-1.8-.5s-1.5.2-2,.5c-.5.3-.8.8-1,1.4l-4.3-.3c.3-1.5,1.1-2.7,2.4-3.6,1.3-.9,2.9-1.4,4.9-1.4s2.4.2,3.5.6,1.9,1,2.6,1.8,1,1.9,1,3.2v11.2h-4.4v-2.3h0c-.4.8-1,1.4-1.8,1.9-.8.5-1.8.7-3.1.7ZM231.9,79.5c1,0,1.8-.3,2.5-.9.6-.6,1-1.3,1-2.2v-1.7c-.2,0-.5.2-.9.3s-.8.2-1.2.2-.8,0-1.2.2c-.8,0-1.5.3-2,.7-.5.3-.7.8-.7,1.5s.2,1.1.7,1.4c.4.2,1.1.4,1.7.4Z"/>
                                    <path d="M241.8,82.4v-22.1h4.7v11.8h.2l5.3-6.3h5.3l-6.2,7.2,6.6,9.4h-5.4l-4.6-6.7-1.3,1.4v5.3h-4.6Z"/>
                                    <path d="M264.4,82.7c-1.7,0-3.2-.3-4.5-1.1-1.2-.7-2.2-1.7-2.9-3-.7-1.3-1-2.8-1-4.6s.3-3.2,1-4.5c.7-1.3,1.6-2.3,2.8-3s2.7-1.1,4.3-1.1,2.8.3,4,.9c1.2.6,2.1,1.6,2.8,2.8.7,1.3,1.1,2.8,1.1,4.7v1.3h-11.4c0,1.2.3,2.2,1,2.9s1.6,1.1,2.8,1.1,1.5-.2,2-.5c.6-.3,1-.8,1.2-1.4l4.3.3c-.3,1.6-1.2,2.8-2.5,3.7-1.3.9-3,1.4-5.1,1.4ZM260.7,72.4h7c0-1-.3-1.8-1-2.4s-1.5-1-2.5-1-1.9.3-2.5,1c-.7.7-1,1.5-1.1,2.4Z"/>
                                    <path d="M273.3,82.4v-16.6h4.5v2.9h.2c.3-1,.8-1.8,1.6-2.3s1.6-.8,2.5-.8,1,0,1.5.2v4.1c-.2,0-.5,0-.9-.2-.4,0-.7,0-1.1,0-1,0-1.9.3-2.5,1-.7.6-1,1.5-1,2.5v9.4h-4.7Z"/>
                                    <path d="M56.7,93l22.3-41.3,19.7-8.7-22.3,41.3-19.7,8.7Z"/>
                                    <path d="M79,83.5l16.9-31.4,9.6,9.5-16.7,31.5-9.9-9.5Z"/>
                                    <path d="M107.4,62.6l12.5,4.6-6.3,11.5-15.2.5,8.9-16.6Z"/>
                                    </g>
                                </g>
                                </g>
                            </svg>
                        </a>
                        <a href="https://www.ibcgroup.io/" class="show-tablet page_customersLink link_root" rel="noopener" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 340.2 136.1" fill="currentColor" class="icon_logotype text-quaternary" style="--Logotype-width: 180px;">
                                <!-- Generator: Adobe Illustrator 28.7.2, SVG Export Plug-In . SVG Version: 1.2.0 Build 154)  -->
                                <g>
                                <g id="_图层_1" data-name="图层_1" focusable="false">
                                    <g>
                                    <path d="M132,62.5h7.6c.8,0,1.4-.6,1.4-1.4v-7.6c0-.8-.6-1.4-1.4-1.4h-7c-.9,0-1.5-.7-1.5-1.5v-7.4c0-.7-.5-1.4-1.2-1.5h-19.5c-.4,0-.8.2-1.1.4l-9.4,9.4c-.3.3-.4.7-.4,1.1v8.5c0,.4,0,.7-.4,1h0c-.3.3-.6.4-1,.4h-7.7c-.4,0-.7,0-1-.4h0c-.2-.3-.4-.6-.4-1v-8.5c0-.4-.2-.8-.4-1.1l-9.6-9.4c-.3-.3-.7-.4-1.1-.4h-18.8c-.8,0-1.5.7-1.5,1.5v7.5c0,.4,0,.7-.4,1h0c-.3.3-.6.4-1,.4h-7.6c-.4,0-.7,0-1-.4h0c-.3-.3-.4-.6-.4-1h0v-7.6c0-.8-.6-1.4-1.4-1.4h-7.6c-.8,0-1.4.6-1.4,1.4v7.6h0c0,.4-.1.7-.4,1h0c-.3.3-.6.4-1,.4h-7.6c-.8,0-1.4.6-1.4,1.4v38.9c0,.8.6,1.4,1.4,1.4h7.6c.8,0,1.4-.6,1.4-1.4v-38.9c0-.4.1-.7.4-1h0c.3-.3.6-.4,1-.4h7.6c.4,0,.7,0,1,.4h0c.3.3.4.6.4,1v38.9c0,.8.6,1.4,1.4,1.4h29.3c.4,0,.8-.2,1.1-.4l9.4-9.4c.3-.3.4-.7.4-1.1v-8.5c0-.4,0-.7.4-1h0c.2-.3.6-.4,1-.4h7.7c.4,0,.7,0,1,.4h0c.3.3.4.6.4,1v8.4c0,.4.2.8.4,1.1l9.5,9.5c.3.3.7.4,1.1.4h29.2c.8,0,1.5-.7,1.5-1.5v-7.4c0-.8-.7-1.5-1.5-1.5h-28.2c-.8,0-1.5-.7-1.5-1.5v-7.4c0-.8-.7-1.5-1.5-1.5h-7.5c-.4,0-.7,0-1-.4h0c-.2-.3-.4-.6-.4-1v-7.6c0-.4,0-.7.4-1s.6-.4,1-.4h7.5c.8,0,1.5-.7,1.5-1.5v-7.4c0-.8.7-1.5,1.5-1.5h17.9c.4,0,.7,0,1,.4.3.3.4.6.4,1v7.6c0,.7.6,1.3,1.4,1.3ZM78.5,81.8c0,.8-.7,1.5-1.5,1.5h-17.9c-.8,0-1.5-.7-1.5-1.5v-7.4c0-.8.7-1.5,1.5-1.5h17.9c.4,0,.7,0,1,.4h0c.3.3.4.6.4,1v7.5h0ZM78.5,61.1c0,.4,0,.7-.4,1h0c-.3.3-.6.4-1,.4h-18c-.8,0-1.5-.7-1.5-1.5v-7.5c0-.4,0-.7.4-1,.3-.3.6-.4,1-.4h18c.8,0,1.5.7,1.5,1.5v7.5ZM88.9,71.5c0,.4,0,.7-.4,1h0c-.3.3-.6.4-1,.4h-7.6c-.4,0-.7,0-1-.4h0c-.3-.3-.4-.6-.4-1v-7.6c0-.4,0-.7.4-1h0c.3-.3.6-.4,1-.4h7.6c.4,0,.7,0,1,.4h0c.3.3.4.6.4,1v7.6Z"/>
                                    <g>
                                        <path d="M171.5,53.8c0,.3-.2.5-.4.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7Z"/>
                                        <path d="M182.5,49.5v-7.4c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7c0,.3-.2.5-.4.5h-2.3c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h2.3c.4,0,.5.2.6.5l3.7,7.4s.2,0,.2,0Z"/>
                                        <path d="M193.8,53.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-9.7h-2.9c-.3,0-.5-.2-.5-.5v-1.5c0-.3.2-.5.5-.5h8.5c.3,0,.4.2.4.5v1.5c0,.3-.2.5-.4.5h-2.9v9.7h0Z"/>
                                        <path d="M202.5,51.8h5.8c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h7.8c.3,0,.4.2.4.5v1.5c0,.3-.2.5-.4.5h-5.6v2.5h4.2c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-4.2v2.7Z"/>
                                        <path d="M219.3,50.1l1.8,3.7c.1.3-.1.5-.3.5h-1.9c-.4,0-.5-.2-.7-.5l-1.6-3.5h-2.3v3.5c0,.3-.2.5-.5.5h-1.8c-.3,0-.4-.2-.4-.5v-11.7c0-.3.2-.5.4-.5h6.2c1.9,0,2.8.9,2.8,2.8v3.1c0,2.4-1.7,2.6-1.7,2.6ZM214.3,44.1v3.8h3.2c.5,0,.9-.4.9-.9v-2c0-.5-.4-.9-.9-.9h-3.2Z"/>
                                        <path d="M231.7,49.5v-7.4c0-.3.2-.5.4-.5h1.8c.3,0,.5.2.5.5v11.7c0,.3-.2.5-.5.5h-2.2c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.7c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h2.3c.4,0,.5.2.6.5l3.7,7.4s0,0,0,0Z"/>
                                        <path d="M240.4,51.7l-.6,2.2c-.1.3-.3.5-.6.5h-2c-.2,0-.4-.2-.3-.5l3.8-11.7c.1-.3.3-.5.6-.5h2.2c.4,0,.5.2.6.5l3.8,11.7c.1.3-.1.5-.3.5h-2c-.4,0-.5-.2-.6-.5l-.6-2.2h-4ZM243.6,49.2l-1.2-4h-.2l-1.2,4h2.6Z"/>
                                        <path d="M253.4,53.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-9.7h-2.9c-.3,0-.4-.2-.4-.5v-1.5c0-.3.2-.5.4-.5h8.5c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-2.9v9.7h0Z"/>
                                        <path d="M262,53.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v11.7Z"/>
                                        <path d="M275.6,51.7c0,1.9-.9,2.8-2.8,2.8h-4.4c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.4c1.9,0,2.8.9,2.8,2.8v7.4ZM272.9,44.8c0-.5-.4-.9-.9-.9h-2.8c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.8c.5,0,.9-.4.9-.9v-6.3Z"/>
                                        <path d="M286.4,49.5v-7.4c0-.3.2-.5.4-.5h1.8c.3,0,.5.2.5.5v11.7c0,.3-.2.5-.5.5h-2.2c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h2.3c.4,0,.5.2.6.5l3.7,7.4s.2,0,.2,0Z"/>
                                        <path d="M295.1,51.7l-.6,2.2c-.1.3-.3.5-.6.5h-2c-.2,0-.4-.2-.3-.5l3.8-11.7c.1-.3.3-.5.6-.5h2.2c.4,0,.5.2.6.5l3.8,11.7c.1.3-.1.5-.3.5h-2c-.4,0-.5-.2-.6-.5l-.6-2.2h-4ZM298.4,49.2l-1.2-4h-.2l-1.2,4h2.6Z"/>
                                        <path d="M307.8,51.8h5.4c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-7.7c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v9.7h0Z"/>
                                        <path d="M168.8,62.1c0-.3.2-.5.5-.5h5.6c1.9,0,2.8.9,2.8,2.8v1.2c0,1.5-1.2,1.8-1.2,1.8v.2s1.8.3,1.8,2.2v1.7c0,1.9-.9,2.8-2.8,2.8h-6.2c-.3,0-.5-.2-.5-.5v-11.7h0ZM171.5,64.1v2.4h2.5c.5,0,.9-.4.9-.9v-.6c0-.5-.4-.9-.9-.9h-2.5ZM174.7,71.8c.5,0,.9-.4.9-.9v-1.2c0-.5-.4-.9-.9-.9h-3.2v3s3.2,0,3.2,0Z"/>
                                        <path d="M184.2,71.8h5.4c.3,0,.5.2.5.4v1.5c0,.3-.2.5-.5.5h-7.7c-.3,0-.5-.2-.5-.5v-11.6c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v9.7Z"/>
                                        <path d="M201.9,71.7c0,1.9-.9,2.8-2.8,2.8h-4.4c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.4c1.9,0,2.8.9,2.8,2.8v7.4ZM199.2,64.8c0-.5-.4-.9-.9-.9h-2.8c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.8c.5,0,.9-.4.9-.9v-6.3Z"/>
                                        <path d="M215.2,65.5c0,.3-.2.4-.5.4h-1.8c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-2.5c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.6c.5,0,.9-.4.9-.9v-.6c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v1.2c0,1.9-.9,2.8-2.8,2.8h-4.2c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.2c1.9,0,2.8.9,2.8,2.8v1.1h-.1Z"/>
                                        <path d="M221.2,66.4h1.7l2-4.3c.1-.3.3-.5.6-.5h2c.2,0,.4.2.3.5l-2.5,5.5,2.9,6.2c.1.3-.1.5-.4.5h-1.8c-.4,0-.6-.2-.7-.5l-2.3-5h-1.8v5c0,.3-.2.5-.5.5h-1.7c-.3,0-.4-.2-.4-.5v-11.7c0-.3.2-.5.4-.5h1.8c.3,0,.5.2.5.5v4.3h-.1Z"/>
                                        <path d="M240,65.5c0,.3-.2.4-.4.4h-1.8c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-2.6c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.6c.5,0,.9-.4.9-.9v-.6c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v1.2c0,1.9-.9,2.8-2.8,2.8h-4.2c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.2c1.9,0,2.8.9,2.8,2.8v1.1Z"/>
                                        <path d="M246,66.8h4.2v-4.7c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7c0,.3-.2.5-.4.5h-1.8c-.3,0-.5-.2-.5-.5v-4.6h-4.2v4.6c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v4.7Z"/>
                                        <path d="M258.9,71.7l-.6,2.2c-.1.3-.3.5-.6.5h-2c-.2,0-.4-.2-.3-.5l3.8-11.7c.1-.3.3-.5.6-.5h2.2c.4,0,.5.2.6.5l3.8,11.7c.1.3-.1.5-.3.5h-2c-.4,0-.5-.2-.6-.5l-.6-2.2h-4ZM262.2,69.2l-1.2-4h-.2l-1.2,4h2.6Z"/>
                                        <path d="M271.6,73.8c0,.3-.2.5-.4.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7Z"/>
                                        <path d="M282.6,69.5v-7.4c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7c0,.3-.2.5-.4.5h-2.3c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h2.3c.4,0,.5.2.6.5l3.7,7.4s.2,0,.2,0Z"/>
                                        <path d="M178.4,85.5c0,.3-.2.4-.5.4h-1.8c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-2.6c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.6c.5,0,.9-.4.9-.9v-.6c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v1.2c0,1.9-.9,2.8-2.8,2.8h-4.2c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.2c1.9,0,2.8.9,2.8,2.8v1.1Z"/>
                                        <path d="M191.6,91.7c0,1.9-.9,2.8-2.8,2.8h-4.4c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.4c1.9,0,2.8.9,2.8,2.8v7.4ZM188.9,84.8c0-.5-.4-.9-.9-.9h-2.8c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.8c.5,0,.9-.4.9-.9v-6.3Z"/>
                                        <path d="M202.4,89.5v-7.4c0-.3.2-.4.5-.4h1.8c.3,0,.4.2.4.4v11.7c0,.3-.2.5-.4.5h-2.3c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.4.5-.4h2.3c.4,0,.5.2.6.4l3.7,7.4h.2Z"/>
                                        <path d="M215.1,90.6c0-.5-.2-.6-.6-.8l-4-1.5c-1.3-.5-2-1.2-2-2.7v-1.3c0-1.9.9-2.8,2.8-2.8h3.5c1.9,0,2.8.9,2.8,2.8v1.2c0,.3-.2.4-.5.4h-1.8c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-1.9c-.5,0-.9.4-.9.9v.5c0,.5.2.6.6.8l4,1.5c1.3.5,2,1.2,2,2.7v1.3c0,1.9-.9,2.8-2.8,2.8h-3.9c-1.9,0-2.8-.9-2.8-2.8v-1.2c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v.6c0,.5.4.9.9.9h2.2c.5,0,.9-.4.9-.9v-.5h0Z"/>
                                        <path d="M221,82.1c0-.3.2-.4.4-.4h1.8c.3,0,.5.2.5.4v9c0,.5.4.9.9.9h2.4c.5,0,.9-.4.9-.9v-9c0-.3.2-.4.4-.4h1.8c.3,0,.5.2.5.4v9.5c0,1.9-.9,2.8-2.8,2.8h-4.1c-1.9,0-2.8-.9-2.8-2.8v-9.5h.1Z"/>
                                        <path d="M237.1,91.9h5.4c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-7.7c-.3,0-.5-.2-.5-.5v-11.8c0-.3.2-.4.5-.4h1.8c.3,0,.5.2.5.4v9.8Z"/>
                                        <path d="M248.5,93.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-9.7h-2.9c-.3,0-.4-.2-.4-.4v-1.5c0-.3.2-.4.4-.4h8.5c.3,0,.5.2.5.4v1.5c0,.3-.2.4-.5.4h-2.9v9.7h0Z"/>
                                        <path d="M257.1,93.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.4.5-.4h1.8c.3,0,.5.2.5.4v11.7Z"/>
                                        <path d="M268.1,89.5v-7.4c0-.3.2-.4.5-.4h1.8c.3,0,.5.2.5.4v11.7c0,.3-.2.5-.5.5h-2.3c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.4-.2-.4-.5v-11.7c0-.3.2-.4.4-.4h2.3c.4,0,.5.2.6.4l3.7,7.4h.2Z"/>
                                        <path d="M284.2,91.7c0,1.9-.9,2.8-2.8,2.8h-4.2c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.2c1.9,0,2.8.9,2.8,2.8v1.2c0,.3-.2.4-.5.4h-1.7c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-2.6c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.6c.5,0,.9-.4.9-.9v-1.4h-1.7c-.3,0-.5-.2-.5-.5v-1.5c0-.3.2-.5.5-.5h4c.3,0,.5.2.5.5v3.9h0Z"/>
                                    </g>
                                    </g>
                                </g>
                                </g>
                            </svg>
                        </a>
                        <a href="https://www.falconx.io/" class="page_customersLink link_root" rel="noopener" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 340.2 136.1" fill="currentColor" class="icon_logotype text-quaternary" style="--Logotype-width: 180px;">
                                <!-- Generator: Adobe Illustrator 28.7.2, SVG Export Plug-In . SVG Version: 1.2.0 Build 154)  -->
                                <g>
                                <g id="_图层_1" data-name="图层_1" focusable="false">
                                    <g>
                                    <path class="cls-1" d="M276.4,71.2l-3.2,4.6,3.8,5.5h6.5l-7.1-10ZM277,54.8l-3.8,5.5,3.2,4.5,7.1-10h-6.4ZM264.7,54.8h-6.5l9.4,13.4-9.1,13h6.5l9-13-9.3-13.4Z"/>
                                    <path class="cls-1" d="M56.7,65.8v15.6h5.5v-10c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h7.7c.8,0,1.4-.4,1.8-1.3l1.2-3.3h-17.1ZM56.7,54.8v4.8h19.2c.8,0,1.4-.4,1.8-1.2l1.4-3.6h-22.4Z"/>
                                    <path class="cls-1" d="M98.8,56.7c-.6-1.3-1.5-1.9-2.8-1.9h-4.3l-10.9,26.6h5.7l8.3-21.2,8.1,21.2h6l-10.1-24.7Z"/>
                                    <path class="cls-1" d="M125.3,76.5v-19.8c0-.5-.2-1-.5-1.4-.4-.4-.8-.5-1.3-.5h-3.6v26.6h16.9c.8,0,1.3-.4,1.7-1.2l1.4-3.7h-14.6Z"/>
                                    <path class="cls-1" d="M168.7,74.5c-.4,0-.8.1-1.1.4-1.6,1.2-3.5,1.9-5.6,1.9s-4.6-.8-6.2-2.5c-1.6-1.7-2.5-3.7-2.5-6.1s.8-4.6,2.4-6.2c1.6-1.6,3.7-2.5,6.1-2.5s4,.7,5.7,2c.4.3.8.4,1.1.4s.7-.1,1.1-.3l3.1-2.1c-1.2-1.5-2.8-2.7-4.8-3.6-2-.9-4-1.4-6.3-1.4s-5,.6-7.2,1.8c-2.2,1.2-3.9,2.8-5.1,4.9s-1.9,4.4-1.9,7,.6,4.7,1.8,6.8c1.2,2.1,2.9,3.7,5,5,2.1,1.3,4.6,1.9,7.4,1.9s4.1-.4,6.1-1.3,3.5-2.1,4.8-3.6l-2.9-2.1c-.4-.2-.7-.3-1.1-.3Z"/>
                                    <path class="cls-1" d="M246.2,54.8h-5.2v19.1c-2.7-3.9-8.7-12.7-11.5-16.8-1-1.4-2.6-2.3-4.3-2.3h-2.6v26.6h5.2v-17.8l10.2,15.4c1,1.5,2.6,2.3,4.3,2.3h3.9v-26.6h0Z"/>
                                    <path class="cls-1" d="M183.5,60.9c-1.2,2.1-1.8,4.4-1.8,6.9s.6,5.1,2,7.1c1.3,2.1,3,3.7,5.2,4.8,1.5.8,3,1.3,4.6,1.6v-5.2c-1.4-.4-2.6-1.1-3.7-2.2-1.6-1.7-2.4-3.7-2.4-6.2s.8-4.6,2.4-6.2c1.1-1.1,2.3-1.8,3.7-2.2v-5.2c-1.8.3-3.4.8-4.9,1.7-2.1,1.2-3.8,2.9-5,5ZM207.8,60.7c-1.3-2.1-3-3.7-5.1-4.8-1.5-.8-3-1.3-4.7-1.6v5.2c1.4.4,2.7,1.1,3.7,2.2,1.6,1.7,2.4,3.7,2.4,6.2s-.8,4.5-2.4,6.2c-1.1,1.1-2.3,1.8-3.7,2.2v5.2c1.8-.3,3.4-.8,4.9-1.7,2.1-1.2,3.8-2.9,5-5,1.2-2.1,1.8-4.4,1.8-6.9,0-2.7-.6-5-1.9-7.1Z"/>
                                    </g>
                                </g>
                                </g>
                            </svg>
                        </a>
                        <a href="https://app.daomaker.com/" class="page_customersLink link_root" rel="noopener" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 340.2 136.1" fill="currentColor" class="icon_logotype text-quaternary" style="--Logotype-width: 180px;">
                                <!-- Generator: Adobe Illustrator 28.7.2, SVG Export Plug-In . SVG Version: 1.2.0 Build 154)  -->
                                <g>
                                <g id="_图层_1" data-name="图层_1" focusable="false">
                                    <g>
                                    <path d="M142.9,82.4h-7.9v-22.1h8c2.2,0,4.2.4,5.8,1.3s2.9,2.1,3.7,3.8c.9,1.7,1.3,3.6,1.3,5.9s-.4,4.3-1.3,5.9c-.9,1.7-2.1,2.9-3.8,3.8-1.6.9-3.5,1.4-5.8,1.4ZM139.7,78.3h3c2.1,0,3.7-.5,4.8-1.7,1.1-1.1,1.6-2.9,1.6-5.4s-.5-4.2-1.6-5.3-2.7-1.7-4.8-1.7h-3v14.1h0Z"/>
                                    <path d="M157.3,82.4h-5l7.7-22.1h6.1l7.7,22.1h-5l-1.7-5h-8.1l-1.7,5ZM160.2,73.7h5.7l-2.7-8.4h-.2l-2.8,8.4Z"/>
                                    <path d="M193.1,71.3c0,2.4-.5,4.5-1.4,6.2s-2.2,3-3.7,3.9c-1.6.9-3.3,1.4-5.3,1.4s-3.8-.4-5.3-1.4c-1.6-.9-2.8-2.2-3.7-3.9-.9-1.7-1.4-3.7-1.4-6.1s.5-4.5,1.4-6.2,2.1-3,3.7-3.9c1.6-.9,3.3-1.4,5.3-1.4s3.7.4,5.3,1.4c1.6.9,2.8,2.2,3.7,3.9,1,1.7,1.4,3.7,1.4,6.1ZM188.4,71.3c0-2.3-.5-4.1-1.6-5.3s-2.4-1.8-4.1-1.8-3.1.6-4.1,1.8c-1,1.3-1.6,3-1.6,5.3s.5,4.1,1.6,5.3,2.4,1.8,4.1,1.8,3.1-.6,4.1-1.8c1-1.2,1.6-3,1.6-5.3Z"/>
                                    <path d="M199.5,60.2h5.8l6.2,14.9h.2l6.2-14.9h5.8v22.1h-4.6v-14.4h-.2l-5.8,14.3h-3.1l-5.8-14.3h-.2v14.5h-4.6v-22.1h0Z"/>
                                    <path d="M230.5,82.7c-1.6,0-2.9-.4-4-1.3-1.1-.8-1.6-2.1-1.6-3.7s.3-2.2.9-2.9c.6-.7,1.4-1.2,2.3-1.6.9-.3,2-.5,3.1-.6,1.5-.1,2.5-.3,3.1-.5.6-.2.9-.5.9-1.1h0c0-.7-.2-1.3-.6-1.6-.4-.4-1-.5-1.8-.5s-1.5.2-2,.5c-.5.3-.8.8-1,1.4l-4.3-.3c.3-1.5,1.1-2.7,2.4-3.6,1.3-.9,2.9-1.4,4.9-1.4s2.4.2,3.5.6,1.9,1,2.6,1.8,1,1.9,1,3.2v11.2h-4.4v-2.3h0c-.4.8-1,1.4-1.8,1.9-.8.5-1.8.7-3.1.7ZM231.9,79.5c1,0,1.8-.3,2.5-.9.6-.6,1-1.3,1-2.2v-1.7c-.2,0-.5.2-.9.3s-.8.2-1.2.2-.8,0-1.2.2c-.8,0-1.5.3-2,.7-.5.3-.7.8-.7,1.5s.2,1.1.7,1.4c.4.2,1.1.4,1.7.4Z"/>
                                    <path d="M241.8,82.4v-22.1h4.7v11.8h.2l5.3-6.3h5.3l-6.2,7.2,6.6,9.4h-5.4l-4.6-6.7-1.3,1.4v5.3h-4.6Z"/>
                                    <path d="M264.4,82.7c-1.7,0-3.2-.3-4.5-1.1-1.2-.7-2.2-1.7-2.9-3-.7-1.3-1-2.8-1-4.6s.3-3.2,1-4.5c.7-1.3,1.6-2.3,2.8-3s2.7-1.1,4.3-1.1,2.8.3,4,.9c1.2.6,2.1,1.6,2.8,2.8.7,1.3,1.1,2.8,1.1,4.7v1.3h-11.4c0,1.2.3,2.2,1,2.9s1.6,1.1,2.8,1.1,1.5-.2,2-.5c.6-.3,1-.8,1.2-1.4l4.3.3c-.3,1.6-1.2,2.8-2.5,3.7-1.3.9-3,1.4-5.1,1.4ZM260.7,72.4h7c0-1-.3-1.8-1-2.4s-1.5-1-2.5-1-1.9.3-2.5,1c-.7.7-1,1.5-1.1,2.4Z"/>
                                    <path d="M273.3,82.4v-16.6h4.5v2.9h.2c.3-1,.8-1.8,1.6-2.3s1.6-.8,2.5-.8,1,0,1.5.2v4.1c-.2,0-.5,0-.9-.2-.4,0-.7,0-1.1,0-1,0-1.9.3-2.5,1-.7.6-1,1.5-1,2.5v9.4h-4.7Z"/>
                                    <path d="M56.7,93l22.3-41.3,19.7-8.7-22.3,41.3-19.7,8.7Z"/>
                                    <path d="M79,83.5l16.9-31.4,9.6,9.5-16.7,31.5-9.9-9.5Z"/>
                                    <path d="M107.4,62.6l12.5,4.6-6.3,11.5-15.2.5,8.9-16.6Z"/>
                                    </g>
                                </g>
                                </g>
                            </svg>
                        </a>
                        <a href="https://www.ibcgroup.io/" class="show-tablet page_customersLink link_root" rel="noopener" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 340.2 136.1" fill="currentColor" class="icon_logotype text-quaternary" style="--Logotype-width: 180px;">
                                <!-- Generator: Adobe Illustrator 28.7.2, SVG Export Plug-In . SVG Version: 1.2.0 Build 154)  -->
                                <g>
                                <g id="_图层_1" data-name="图层_1" focusable="false">
                                    <g>
                                    <path d="M132,62.5h7.6c.8,0,1.4-.6,1.4-1.4v-7.6c0-.8-.6-1.4-1.4-1.4h-7c-.9,0-1.5-.7-1.5-1.5v-7.4c0-.7-.5-1.4-1.2-1.5h-19.5c-.4,0-.8.2-1.1.4l-9.4,9.4c-.3.3-.4.7-.4,1.1v8.5c0,.4,0,.7-.4,1h0c-.3.3-.6.4-1,.4h-7.7c-.4,0-.7,0-1-.4h0c-.2-.3-.4-.6-.4-1v-8.5c0-.4-.2-.8-.4-1.1l-9.6-9.4c-.3-.3-.7-.4-1.1-.4h-18.8c-.8,0-1.5.7-1.5,1.5v7.5c0,.4,0,.7-.4,1h0c-.3.3-.6.4-1,.4h-7.6c-.4,0-.7,0-1-.4h0c-.3-.3-.4-.6-.4-1h0v-7.6c0-.8-.6-1.4-1.4-1.4h-7.6c-.8,0-1.4.6-1.4,1.4v7.6h0c0,.4-.1.7-.4,1h0c-.3.3-.6.4-1,.4h-7.6c-.8,0-1.4.6-1.4,1.4v38.9c0,.8.6,1.4,1.4,1.4h7.6c.8,0,1.4-.6,1.4-1.4v-38.9c0-.4.1-.7.4-1h0c.3-.3.6-.4,1-.4h7.6c.4,0,.7,0,1,.4h0c.3.3.4.6.4,1v38.9c0,.8.6,1.4,1.4,1.4h29.3c.4,0,.8-.2,1.1-.4l9.4-9.4c.3-.3.4-.7.4-1.1v-8.5c0-.4,0-.7.4-1h0c.2-.3.6-.4,1-.4h7.7c.4,0,.7,0,1,.4h0c.3.3.4.6.4,1v8.4c0,.4.2.8.4,1.1l9.5,9.5c.3.3.7.4,1.1.4h29.2c.8,0,1.5-.7,1.5-1.5v-7.4c0-.8-.7-1.5-1.5-1.5h-28.2c-.8,0-1.5-.7-1.5-1.5v-7.4c0-.8-.7-1.5-1.5-1.5h-7.5c-.4,0-.7,0-1-.4h0c-.2-.3-.4-.6-.4-1v-7.6c0-.4,0-.7.4-1s.6-.4,1-.4h7.5c.8,0,1.5-.7,1.5-1.5v-7.4c0-.8.7-1.5,1.5-1.5h17.9c.4,0,.7,0,1,.4.3.3.4.6.4,1v7.6c0,.7.6,1.3,1.4,1.3ZM78.5,81.8c0,.8-.7,1.5-1.5,1.5h-17.9c-.8,0-1.5-.7-1.5-1.5v-7.4c0-.8.7-1.5,1.5-1.5h17.9c.4,0,.7,0,1,.4h0c.3.3.4.6.4,1v7.5h0ZM78.5,61.1c0,.4,0,.7-.4,1h0c-.3.3-.6.4-1,.4h-18c-.8,0-1.5-.7-1.5-1.5v-7.5c0-.4,0-.7.4-1,.3-.3.6-.4,1-.4h18c.8,0,1.5.7,1.5,1.5v7.5ZM88.9,71.5c0,.4,0,.7-.4,1h0c-.3.3-.6.4-1,.4h-7.6c-.4,0-.7,0-1-.4h0c-.3-.3-.4-.6-.4-1v-7.6c0-.4,0-.7.4-1h0c.3-.3.6-.4,1-.4h7.6c.4,0,.7,0,1,.4h0c.3.3.4.6.4,1v7.6Z"/>
                                    <g>
                                        <path d="M171.5,53.8c0,.3-.2.5-.4.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7Z"/>
                                        <path d="M182.5,49.5v-7.4c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7c0,.3-.2.5-.4.5h-2.3c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h2.3c.4,0,.5.2.6.5l3.7,7.4s.2,0,.2,0Z"/>
                                        <path d="M193.8,53.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-9.7h-2.9c-.3,0-.5-.2-.5-.5v-1.5c0-.3.2-.5.5-.5h8.5c.3,0,.4.2.4.5v1.5c0,.3-.2.5-.4.5h-2.9v9.7h0Z"/>
                                        <path d="M202.5,51.8h5.8c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h7.8c.3,0,.4.2.4.5v1.5c0,.3-.2.5-.4.5h-5.6v2.5h4.2c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-4.2v2.7Z"/>
                                        <path d="M219.3,50.1l1.8,3.7c.1.3-.1.5-.3.5h-1.9c-.4,0-.5-.2-.7-.5l-1.6-3.5h-2.3v3.5c0,.3-.2.5-.5.5h-1.8c-.3,0-.4-.2-.4-.5v-11.7c0-.3.2-.5.4-.5h6.2c1.9,0,2.8.9,2.8,2.8v3.1c0,2.4-1.7,2.6-1.7,2.6ZM214.3,44.1v3.8h3.2c.5,0,.9-.4.9-.9v-2c0-.5-.4-.9-.9-.9h-3.2Z"/>
                                        <path d="M231.7,49.5v-7.4c0-.3.2-.5.4-.5h1.8c.3,0,.5.2.5.5v11.7c0,.3-.2.5-.5.5h-2.2c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.7c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h2.3c.4,0,.5.2.6.5l3.7,7.4s0,0,0,0Z"/>
                                        <path d="M240.4,51.7l-.6,2.2c-.1.3-.3.5-.6.5h-2c-.2,0-.4-.2-.3-.5l3.8-11.7c.1-.3.3-.5.6-.5h2.2c.4,0,.5.2.6.5l3.8,11.7c.1.3-.1.5-.3.5h-2c-.4,0-.5-.2-.6-.5l-.6-2.2h-4ZM243.6,49.2l-1.2-4h-.2l-1.2,4h2.6Z"/>
                                        <path d="M253.4,53.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-9.7h-2.9c-.3,0-.4-.2-.4-.5v-1.5c0-.3.2-.5.4-.5h8.5c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-2.9v9.7h0Z"/>
                                        <path d="M262,53.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v11.7Z"/>
                                        <path d="M275.6,51.7c0,1.9-.9,2.8-2.8,2.8h-4.4c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.4c1.9,0,2.8.9,2.8,2.8v7.4ZM272.9,44.8c0-.5-.4-.9-.9-.9h-2.8c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.8c.5,0,.9-.4.9-.9v-6.3Z"/>
                                        <path d="M286.4,49.5v-7.4c0-.3.2-.5.4-.5h1.8c.3,0,.5.2.5.5v11.7c0,.3-.2.5-.5.5h-2.2c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h2.3c.4,0,.5.2.6.5l3.7,7.4s.2,0,.2,0Z"/>
                                        <path d="M295.1,51.7l-.6,2.2c-.1.3-.3.5-.6.5h-2c-.2,0-.4-.2-.3-.5l3.8-11.7c.1-.3.3-.5.6-.5h2.2c.4,0,.5.2.6.5l3.8,11.7c.1.3-.1.5-.3.5h-2c-.4,0-.5-.2-.6-.5l-.6-2.2h-4ZM298.4,49.2l-1.2-4h-.2l-1.2,4h2.6Z"/>
                                        <path d="M307.8,51.8h5.4c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-7.7c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v9.7h0Z"/>
                                        <path d="M168.8,62.1c0-.3.2-.5.5-.5h5.6c1.9,0,2.8.9,2.8,2.8v1.2c0,1.5-1.2,1.8-1.2,1.8v.2s1.8.3,1.8,2.2v1.7c0,1.9-.9,2.8-2.8,2.8h-6.2c-.3,0-.5-.2-.5-.5v-11.7h0ZM171.5,64.1v2.4h2.5c.5,0,.9-.4.9-.9v-.6c0-.5-.4-.9-.9-.9h-2.5ZM174.7,71.8c.5,0,.9-.4.9-.9v-1.2c0-.5-.4-.9-.9-.9h-3.2v3s3.2,0,3.2,0Z"/>
                                        <path d="M184.2,71.8h5.4c.3,0,.5.2.5.4v1.5c0,.3-.2.5-.5.5h-7.7c-.3,0-.5-.2-.5-.5v-11.6c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v9.7Z"/>
                                        <path d="M201.9,71.7c0,1.9-.9,2.8-2.8,2.8h-4.4c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.4c1.9,0,2.8.9,2.8,2.8v7.4ZM199.2,64.8c0-.5-.4-.9-.9-.9h-2.8c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.8c.5,0,.9-.4.9-.9v-6.3Z"/>
                                        <path d="M215.2,65.5c0,.3-.2.4-.5.4h-1.8c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-2.5c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.6c.5,0,.9-.4.9-.9v-.6c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v1.2c0,1.9-.9,2.8-2.8,2.8h-4.2c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.2c1.9,0,2.8.9,2.8,2.8v1.1h-.1Z"/>
                                        <path d="M221.2,66.4h1.7l2-4.3c.1-.3.3-.5.6-.5h2c.2,0,.4.2.3.5l-2.5,5.5,2.9,6.2c.1.3-.1.5-.4.5h-1.8c-.4,0-.6-.2-.7-.5l-2.3-5h-1.8v5c0,.3-.2.5-.5.5h-1.7c-.3,0-.4-.2-.4-.5v-11.7c0-.3.2-.5.4-.5h1.8c.3,0,.5.2.5.5v4.3h-.1Z"/>
                                        <path d="M240,65.5c0,.3-.2.4-.4.4h-1.8c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-2.6c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.6c.5,0,.9-.4.9-.9v-.6c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v1.2c0,1.9-.9,2.8-2.8,2.8h-4.2c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.2c1.9,0,2.8.9,2.8,2.8v1.1Z"/>
                                        <path d="M246,66.8h4.2v-4.7c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7c0,.3-.2.5-.4.5h-1.8c-.3,0-.5-.2-.5-.5v-4.6h-4.2v4.6c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v4.7Z"/>
                                        <path d="M258.9,71.7l-.6,2.2c-.1.3-.3.5-.6.5h-2c-.2,0-.4-.2-.3-.5l3.8-11.7c.1-.3.3-.5.6-.5h2.2c.4,0,.5.2.6.5l3.8,11.7c.1.3-.1.5-.3.5h-2c-.4,0-.5-.2-.6-.5l-.6-2.2h-4ZM262.2,69.2l-1.2-4h-.2l-1.2,4h2.6Z"/>
                                        <path d="M271.6,73.8c0,.3-.2.5-.4.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7Z"/>
                                        <path d="M282.6,69.5v-7.4c0-.3.2-.5.5-.5h1.8c.3,0,.4.2.4.5v11.7c0,.3-.2.5-.4.5h-2.3c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.5.5-.5h2.3c.4,0,.5.2.6.5l3.7,7.4s.2,0,.2,0Z"/>
                                        <path d="M178.4,85.5c0,.3-.2.4-.5.4h-1.8c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-2.6c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.6c.5,0,.9-.4.9-.9v-.6c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v1.2c0,1.9-.9,2.8-2.8,2.8h-4.2c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.2c1.9,0,2.8.9,2.8,2.8v1.1Z"/>
                                        <path d="M191.6,91.7c0,1.9-.9,2.8-2.8,2.8h-4.4c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.4c1.9,0,2.8.9,2.8,2.8v7.4ZM188.9,84.8c0-.5-.4-.9-.9-.9h-2.8c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.8c.5,0,.9-.4.9-.9v-6.3Z"/>
                                        <path d="M202.4,89.5v-7.4c0-.3.2-.4.5-.4h1.8c.3,0,.4.2.4.4v11.7c0,.3-.2.5-.4.5h-2.3c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.4.5-.4h2.3c.4,0,.5.2.6.4l3.7,7.4h.2Z"/>
                                        <path d="M215.1,90.6c0-.5-.2-.6-.6-.8l-4-1.5c-1.3-.5-2-1.2-2-2.7v-1.3c0-1.9.9-2.8,2.8-2.8h3.5c1.9,0,2.8.9,2.8,2.8v1.2c0,.3-.2.4-.5.4h-1.8c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-1.9c-.5,0-.9.4-.9.9v.5c0,.5.2.6.6.8l4,1.5c1.3.5,2,1.2,2,2.7v1.3c0,1.9-.9,2.8-2.8,2.8h-3.9c-1.9,0-2.8-.9-2.8-2.8v-1.2c0-.3.2-.5.5-.5h1.8c.3,0,.5.2.5.5v.6c0,.5.4.9.9.9h2.2c.5,0,.9-.4.9-.9v-.5h0Z"/>
                                        <path d="M221,82.1c0-.3.2-.4.4-.4h1.8c.3,0,.5.2.5.4v9c0,.5.4.9.9.9h2.4c.5,0,.9-.4.9-.9v-9c0-.3.2-.4.4-.4h1.8c.3,0,.5.2.5.4v9.5c0,1.9-.9,2.8-2.8,2.8h-4.1c-1.9,0-2.8-.9-2.8-2.8v-9.5h.1Z"/>
                                        <path d="M237.1,91.9h5.4c.3,0,.5.2.5.5v1.5c0,.3-.2.5-.5.5h-7.7c-.3,0-.5-.2-.5-.5v-11.8c0-.3.2-.4.5-.4h1.8c.3,0,.5.2.5.4v9.8Z"/>
                                        <path d="M248.5,93.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-9.7h-2.9c-.3,0-.4-.2-.4-.4v-1.5c0-.3.2-.4.4-.4h8.5c.3,0,.5.2.5.4v1.5c0,.3-.2.4-.5.4h-2.9v9.7h0Z"/>
                                        <path d="M257.1,93.8c0,.3-.2.5-.5.5h-1.8c-.3,0-.5-.2-.5-.5v-11.7c0-.3.2-.4.5-.4h1.8c.3,0,.5.2.5.4v11.7Z"/>
                                        <path d="M268.1,89.5v-7.4c0-.3.2-.4.5-.4h1.8c.3,0,.5.2.5.4v11.7c0,.3-.2.5-.5.5h-2.3c-.4,0-.5-.2-.6-.5l-3.7-7.4h-.2v7.4c0,.3-.2.5-.5.5h-1.8c-.3,0-.4-.2-.4-.5v-11.7c0-.3.2-.4.4-.4h2.3c.4,0,.5.2.6.4l3.7,7.4h.2Z"/>
                                        <path d="M284.2,91.7c0,1.9-.9,2.8-2.8,2.8h-4.2c-1.9,0-2.8-.9-2.8-2.8v-7.4c0-1.9.9-2.8,2.8-2.8h4.2c1.9,0,2.8.9,2.8,2.8v1.2c0,.3-.2.4-.5.4h-1.7c-.3,0-.5-.2-.5-.4v-.6c0-.5-.4-.9-.9-.9h-2.6c-.5,0-.9.4-.9.9v6.3c0,.5.4.9.9.9h2.6c.5,0,.9-.4.9-.9v-1.4h-1.7c-.3,0-.5-.2-.5-.5v-1.5c0-.3.2-.5.5-.5h4c.3,0,.5.2.5.5v3.9h0Z"/>
                                    </g>
                                    </g>
                                </g>
                                </g>
                            </svg>
                        </a>
                        <a href="https://www.falconx.io/" class="page_customersLink link_root" rel="noopener" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 340.2 136.1" fill="currentColor" class="icon_logotype text-quaternary" style="--Logotype-width: 180px;">
                                <!-- Generator: Adobe Illustrator 28.7.2, SVG Export Plug-In . SVG Version: 1.2.0 Build 154)  -->
                                <g>
                                <g id="_图层_1" data-name="图层_1" focusable="false">
                                    <g>
                                    <path class="cls-1" d="M276.4,71.2l-3.2,4.6,3.8,5.5h6.5l-7.1-10ZM277,54.8l-3.8,5.5,3.2,4.5,7.1-10h-6.4ZM264.7,54.8h-6.5l9.4,13.4-9.1,13h6.5l9-13-9.3-13.4Z"/>
                                    <path class="cls-1" d="M56.7,65.8v15.6h5.5v-10c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h7.7c.8,0,1.4-.4,1.8-1.3l1.2-3.3h-17.1ZM56.7,54.8v4.8h19.2c.8,0,1.4-.4,1.8-1.2l1.4-3.6h-22.4Z"/>
                                    <path class="cls-1" d="M98.8,56.7c-.6-1.3-1.5-1.9-2.8-1.9h-4.3l-10.9,26.6h5.7l8.3-21.2,8.1,21.2h6l-10.1-24.7Z"/>
                                    <path class="cls-1" d="M125.3,76.5v-19.8c0-.5-.2-1-.5-1.4-.4-.4-.8-.5-1.3-.5h-3.6v26.6h16.9c.8,0,1.3-.4,1.7-1.2l1.4-3.7h-14.6Z"/>
                                    <path class="cls-1" d="M168.7,74.5c-.4,0-.8.1-1.1.4-1.6,1.2-3.5,1.9-5.6,1.9s-4.6-.8-6.2-2.5c-1.6-1.7-2.5-3.7-2.5-6.1s.8-4.6,2.4-6.2c1.6-1.6,3.7-2.5,6.1-2.5s4,.7,5.7,2c.4.3.8.4,1.1.4s.7-.1,1.1-.3l3.1-2.1c-1.2-1.5-2.8-2.7-4.8-3.6-2-.9-4-1.4-6.3-1.4s-5,.6-7.2,1.8c-2.2,1.2-3.9,2.8-5.1,4.9s-1.9,4.4-1.9,7,.6,4.7,1.8,6.8c1.2,2.1,2.9,3.7,5,5,2.1,1.3,4.6,1.9,7.4,1.9s4.1-.4,6.1-1.3,3.5-2.1,4.8-3.6l-2.9-2.1c-.4-.2-.7-.3-1.1-.3Z"/>
                                    <path class="cls-1" d="M246.2,54.8h-5.2v19.1c-2.7-3.9-8.7-12.7-11.5-16.8-1-1.4-2.6-2.3-4.3-2.3h-2.6v26.6h5.2v-17.8l10.2,15.4c1,1.5,2.6,2.3,4.3,2.3h3.9v-26.6h0Z"/>
                                    <path class="cls-1" d="M183.5,60.9c-1.2,2.1-1.8,4.4-1.8,6.9s.6,5.1,2,7.1c1.3,2.1,3,3.7,5.2,4.8,1.5.8,3,1.3,4.6,1.6v-5.2c-1.4-.4-2.6-1.1-3.7-2.2-1.6-1.7-2.4-3.7-2.4-6.2s.8-4.6,2.4-6.2c1.1-1.1,2.3-1.8,3.7-2.2v-5.2c-1.8.3-3.4.8-4.9,1.7-2.1,1.2-3.8,2.9-5,5ZM207.8,60.7c-1.3-2.1-3-3.7-5.1-4.8-1.5-.8-3-1.3-4.7-1.6v5.2c1.4.4,2.7,1.1,3.7,2.2,1.6,1.7,2.4,3.7,2.4,6.2s-.8,4.5-2.4,6.2c-1.1,1.1-2.3,1.8-3.7,2.2v5.2c1.8-.3,3.4-.8,4.9-1.7,2.1-1.2,3.8-2.9,5-5,1.2-2.1,1.8-4.4,1.8-6.9,0-2.7-.6-5-1.9-7.1Z"/>
                                    </g>
                                </g>
                                </g>
                            </svg>
                        </a>
                        <a href="https://app.daomaker.com/" class="page_customersLink link_root" rel="noopener" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 340.2 136.1" fill="currentColor" class="icon_logotype text-quaternary" style="--Logotype-width: 180px;">
                                <!-- Generator: Adobe Illustrator 28.7.2, SVG Export Plug-In . SVG Version: 1.2.0 Build 154)  -->
                                <g>
                                <g id="_图层_1" data-name="图层_1" focusable="false">
                                    <g>
                                    <path d="M142.9,82.4h-7.9v-22.1h8c2.2,0,4.2.4,5.8,1.3s2.9,2.1,3.7,3.8c.9,1.7,1.3,3.6,1.3,5.9s-.4,4.3-1.3,5.9c-.9,1.7-2.1,2.9-3.8,3.8-1.6.9-3.5,1.4-5.8,1.4ZM139.7,78.3h3c2.1,0,3.7-.5,4.8-1.7,1.1-1.1,1.6-2.9,1.6-5.4s-.5-4.2-1.6-5.3-2.7-1.7-4.8-1.7h-3v14.1h0Z"/>
                                    <path d="M157.3,82.4h-5l7.7-22.1h6.1l7.7,22.1h-5l-1.7-5h-8.1l-1.7,5ZM160.2,73.7h5.7l-2.7-8.4h-.2l-2.8,8.4Z"/>
                                    <path d="M193.1,71.3c0,2.4-.5,4.5-1.4,6.2s-2.2,3-3.7,3.9c-1.6.9-3.3,1.4-5.3,1.4s-3.8-.4-5.3-1.4c-1.6-.9-2.8-2.2-3.7-3.9-.9-1.7-1.4-3.7-1.4-6.1s.5-4.5,1.4-6.2,2.1-3,3.7-3.9c1.6-.9,3.3-1.4,5.3-1.4s3.7.4,5.3,1.4c1.6.9,2.8,2.2,3.7,3.9,1,1.7,1.4,3.7,1.4,6.1ZM188.4,71.3c0-2.3-.5-4.1-1.6-5.3s-2.4-1.8-4.1-1.8-3.1.6-4.1,1.8c-1,1.3-1.6,3-1.6,5.3s.5,4.1,1.6,5.3,2.4,1.8,4.1,1.8,3.1-.6,4.1-1.8c1-1.2,1.6-3,1.6-5.3Z"/>
                                    <path d="M199.5,60.2h5.8l6.2,14.9h.2l6.2-14.9h5.8v22.1h-4.6v-14.4h-.2l-5.8,14.3h-3.1l-5.8-14.3h-.2v14.5h-4.6v-22.1h0Z"/>
                                    <path d="M230.5,82.7c-1.6,0-2.9-.4-4-1.3-1.1-.8-1.6-2.1-1.6-3.7s.3-2.2.9-2.9c.6-.7,1.4-1.2,2.3-1.6.9-.3,2-.5,3.1-.6,1.5-.1,2.5-.3,3.1-.5.6-.2.9-.5.9-1.1h0c0-.7-.2-1.3-.6-1.6-.4-.4-1-.5-1.8-.5s-1.5.2-2,.5c-.5.3-.8.8-1,1.4l-4.3-.3c.3-1.5,1.1-2.7,2.4-3.6,1.3-.9,2.9-1.4,4.9-1.4s2.4.2,3.5.6,1.9,1,2.6,1.8,1,1.9,1,3.2v11.2h-4.4v-2.3h0c-.4.8-1,1.4-1.8,1.9-.8.5-1.8.7-3.1.7ZM231.9,79.5c1,0,1.8-.3,2.5-.9.6-.6,1-1.3,1-2.2v-1.7c-.2,0-.5.2-.9.3s-.8.2-1.2.2-.8,0-1.2.2c-.8,0-1.5.3-2,.7-.5.3-.7.8-.7,1.5s.2,1.1.7,1.4c.4.2,1.1.4,1.7.4Z"/>
                                    <path d="M241.8,82.4v-22.1h4.7v11.8h.2l5.3-6.3h5.3l-6.2,7.2,6.6,9.4h-5.4l-4.6-6.7-1.3,1.4v5.3h-4.6Z"/>
                                    <path d="M264.4,82.7c-1.7,0-3.2-.3-4.5-1.1-1.2-.7-2.2-1.7-2.9-3-.7-1.3-1-2.8-1-4.6s.3-3.2,1-4.5c.7-1.3,1.6-2.3,2.8-3s2.7-1.1,4.3-1.1,2.8.3,4,.9c1.2.6,2.1,1.6,2.8,2.8.7,1.3,1.1,2.8,1.1,4.7v1.3h-11.4c0,1.2.3,2.2,1,2.9s1.6,1.1,2.8,1.1,1.5-.2,2-.5c.6-.3,1-.8,1.2-1.4l4.3.3c-.3,1.6-1.2,2.8-2.5,3.7-1.3.9-3,1.4-5.1,1.4ZM260.7,72.4h7c0-1-.3-1.8-1-2.4s-1.5-1-2.5-1-1.9.3-2.5,1c-.7.7-1,1.5-1.1,2.4Z"/>
                                    <path d="M273.3,82.4v-16.6h4.5v2.9h.2c.3-1,.8-1.8,1.6-2.3s1.6-.8,2.5-.8,1,0,1.5.2v4.1c-.2,0-.5,0-.9-.2-.4,0-.7,0-1.1,0-1,0-1.9.3-2.5,1-.7.6-1,1.5-1,2.5v9.4h-4.7Z"/>
                                    <path d="M56.7,93l22.3-41.3,19.7-8.7-22.3,41.3-19.7,8.7Z"/>
                                    <path d="M79,83.5l16.9-31.4,9.6,9.5-16.7,31.5-9.9-9.5Z"/>
                                    <path d="M107.4,62.6l12.5,4.6-6.3,11.5-15.2.5,8.9-16.6Z"/>
                                    </g>
                                </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    export default {
        data(){
            return {
    
            }
        },
        components: {  },
        created(){
    
        },
        mounted(){
    
        },
        computed: {
            ...mapGetters(["darkTheme"])
        },
        watch:{
    
        },
        methods: {
    
        }
    }
</script>
<style scoped></style>