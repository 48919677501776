<template>
	<div class="layoutContent">
        <div class="Grid_root__iRfoa grid-style-17">
            <div class="Grid_a__vY7M8">
                <div class="d-flex flex-column r-text-center">
                    <p class="fs-17 lexend-deca text-secondary gradient-underline-hover">
                        Powered by the
                            <a href="https://erc-5173.org" target="_blank" class="pointer text-secondary hero-underline-hover del-underline">
                                <span class="gradient-text-hover">ERC-5173</span>
                            </a> 
                        Standard
                    </p>
                    <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                    <h1 class="hero-56 marcellus-sc text-quaternary">
                        Invest Together.
                    </h1>
                    <h1 class="hero-56 marcellus-sc text-quaternary">
                        Win Together.
                    </h1>
                    <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                    <p class="fs-21 lexend-deca text-tertiary">
                        Untrading is the new DeFi economic foundation for community-driven wealth creation. At its core untrading realizes the “win and help win” philosophy in DeFi.
                    </p>
                    <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                    <p class="fs-21 lexend-deca text-tertiary">
                        Profits continue after selling assets.
                    </p>
                    <div class="Spacer_root__uoSvA" style="--height:40px"></div>
                    <!-- <div class="hide-mobile">
                        <div class="d-flex align-center ga-10">
                            <div style="opacity: 1; filter: blur(0px); transform: translateY(0%); will-change: transform;">
                                <router-link to="/uncryptos" class="del-underline">
                                    <button class="btn button_root button_size-default button_variant">Apps</button>
                                </router-link>
                            </div>
                            <div style="opacity: .9; filter: blur(0px); transform: translateY(0%); will-change: transform;">
                                <a href="https://untrading.medium.com/mastering-untradings-profit-expectancy-76166f6abcfb" target="_blank" type="button" class="button_root button_variant-ghost button_size-default button_variant link_root" rel="noopener">
                                    <span class="gjcrSl" style="padding: 0px;"><span class="text-black01">New: </span>Profit Expectancy Setting</span><v-icon class="text-black01">mdi mdi-chevron-right</v-icon>
                                </a>
                            </div>
                        </div>
                    </div> -->
                    <div class="hide-mobile">
                        <div class="d-flex align-center ga-10">
                            <div style="opacity: 1; filter: blur(0px); transform: translateY(0%); will-change: transform;">
                                <a href="https://testnet.untrading.org/uncryptos" target="_blank" type="button" class="del-underline">
                                    <button class="btn button_root button_size-default button_variant">Testnet</button>
                                </a>
                            </div>
                            <div style="opacity: .9; filter: blur(0px); transform: translateY(0%); will-change: transform;">
                                <a href="https://demo.untrading.org" target="_blank" type="button" class="button_root button_variant-ghost button_size-default button_variant link_root" rel="noopener">
                                    <span class="gjcrSl" style="padding: 0px;"><span class="text-black01">New: </span>Demo</span><v-icon class="text-black01">mdi mdi-chevron-right</v-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="show-mobile">
                        <div class="d-flex align-center flex-column justify-center ga-10">
                            <div style="opacity: 1; filter: blur(0px); transform: translateY(0%); will-change: transform;">
                                <router-link to="/uncryptos" class="del-underline">
                                    <button class="btn button_root button_size-default button_variant lexend-deca">Apps</button>
                                </router-link>
                            </div>
                            <div>
                                <a  href="https://untrading.medium.com/mastering-untradings-profit-expectancy-76166f6abcfb" target="_blank" type="button" class="button_root button_variant-ghost button_size-default button_variant link_root" rel="noopener">
                                    <span class="gjcrSl lexend-deca" style="padding: 0px;">New: Profit Expectancy Setting</span><v-icon color="grey-darken-4">mdi mdi-chevron-right</v-icon>
                                </a>
                            </div>
                        </div>
                    </div> -->
                    <div class="show-mobile">
                        <div class="d-flex align-center flex-column justify-center ga-10">
                            <div style="opacity: 1; filter: blur(0px); transform: translateY(0%); will-change: transform;">
                                <a href="https://testnet.untrading.org/uncryptos" target="_blank" type="button" class="del-underline">
                                    <button class="btn button_root button_size-default button_variant lexend-deca">Testnet</button>
                                </a>
                            </div>
                            <div>
                                <a href="https://demo.untrading.org" target="_blank" type="button" class="button_root button_variant-ghost button_size-default button_variant link_root" rel="noopener">
                                    <span class="gjcrSl lexend-deca" style="padding: 0px;"><span class="text-black01">New: </span>Demo</span><v-icon class="text-black01">mdi mdi-chevron-right</v-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: { },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
</style>