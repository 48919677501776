<template>
	<div>
        <v-container>
            <v-card color="transparent" elevation="0" rounded="0" max-width="800" class="mx-auto pt-8">
                <v-tabs hide-slider v-model="type" density="compact" height="50" class="font-weight-bold text-primaryGrey">
                    <router-link to="/leaderboards?type=user-rewards">
                        <v-tab value="user-rewards" replace rounded="0" width="150" class="px-0 text-none" :class="type == 'user-rewards' ? 'bg-primary text-white' : ''">
                            <h4>User Rewards</h4>
                        </v-tab>
                    </router-link>
                    <router-link to="/leaderboards?type=kingdom-rewards">
                        <v-tab value="kingdom-rewards" replace rounded="0" width="200" class="px-0 text-none" :class="type == 'kingdom-rewards' ? 'bg-primary text-white' : ''">
                            <h4>Kingdom Rewards</h4>
                        </v-tab>
                    </router-link>
                    <router-link to="/leaderboards?type=quest">
                        <v-tab value="quest" replace rounded="0" width="250" class="px-0 text-none" :class="type == 'quest' ? 'bg-primary text-white' : ''">
                            <h4>Quest: Learn to Earn</h4>
                        </v-tab>
                    </router-link>
                </v-tabs>
                <v-divider class="border-opacity-100" thickness="3" color="primary"></v-divider>
                <v-card-text class="px-0">
                    <v-window v-model="type">
                        <v-window-item value="user-rewards">
                            <UserRewardLeaderboards></UserRewardLeaderboards>
                        </v-window-item>
                        <v-window-item value="kingdom-rewards">
                            <UserRewardKingdomLeaderboards></UserRewardKingdomLeaderboards>
                        </v-window-item>
                        <v-window-item value="quest">
                            <Quest></Quest>
                        </v-window-item>
                    </v-window>
                </v-card-text>
            </v-card>
        </v-container>
	</div>
</template>
<script>
import UserRewardLeaderboards from '@/components/user/leaderboard/UserRewardLeaderboards.vue';
import UserRewardKingdomLeaderboards from '@/components/user/leaderboard/UserRewardKingdomLeaderboards.vue';
import Quest from '@/components/user/leaderboard/Quest.vue';
export default {
    data(){
        return {
            // 类型
            type: this.$route.query?.type?.toLowerCase(),
        }
    },
    components: { UserRewardLeaderboards, UserRewardKingdomLeaderboards, Quest },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{
        $route: {
            handler(n, o) {
                this.type = this.$route.query?.type?.toLowerCase();
            },
            immediate: true
        },
    },
    methods: {

    }
}
</script>
<style scoped>

</style>