<template>
	<div class="mt-16">
        <div class="footer-glow-bottom-left"></div>
        <v-container class="pa-0 px-0">
            <v-row no-gutters class="pb-8 lexend-deca" justify="center" align="end">
                <v-col cols="12" sm="12" md="7" xl="7" class="px-0">
                    <div class="pointer">
                        <router-link to="/">
                            <div class="logo" v-show="this.darkTheme == 0" >
                                <logo type="footer" color="light" responsive="pc"></logo>
                            </div>
                            <div class="logo" v-show="this.darkTheme == 1" >
                                <logo type="footer" color="dark" responsive="pc"></logo>   
                            </div>
                        </router-link>
                    </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="12" md="5" class="px-0 r-p-l-0 r-m-t-8">
                    <v-row no-gutters justify="end" align="end">
                       <div  class="d-flex r-flex-column">
                            <div class="text-primaryGrey mr-16 fs-16"> Built on</div>
                            <div class="d-flex r-m-t-4 mb-2">
                                <div>
                                    <v-img width="24" height="24"  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/785aaf1b-77e4-4c87-643c-3cbe14796800/public" contain></v-img>
                                </div>
                                <div class="px-2">
                                    <v-img width="24" height="24"  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/12ad746b-ad78-4223-8abb-678a4970b900/public" contain></v-img>
                                </div>
                                <div>
                                    <v-img width="24" height="24"  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/2cf02df1-ea1f-4815-cb23-eb271c056b00/public" contain></v-img>
                                </div>
                            </div>
                       </div>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <div class="d-flex mt-10 r-m-t-16">
                        <div>
                            <a href="https://x.com/untradingOrg" target="_blank" class="del-underline">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/d63c1182-e0bd-44cd-d5ad-7334f9b6b100/public"  aria-label="X (Twitter)" role="img" width="24" height="24"></v-img>
                            </a>
                        </div>
                        <div class="px-2">
                            <a href="https://t.me/untrading" target="_blank" class="black--text del-underline" aria-label="Telegram">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/88bd42d6-38b2-4775-ebdc-b5d28b927500/public" width="24" height="24"></v-img>
                            </a>
                        </div>
                        <div>
                            <a href="https://discord.gg/D5rZdhzVWh" target="_blank" class="black--text del-underline" aria-label="Discord">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ac803eba-9354-40bd-3de0-1c6ab1cc6d00/public" width="24" height="24"></v-img>
                            </a>
                        </div>
                        <div class="px-2">
                            <a href="https://github.com/untrading" target="_blank" class="text-decoration-none" aria-label="GitHub">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/48737c90-42b0-4069-a44a-e3a4616fdb00/public" width="24" height="24"></v-img>
                            </a>
                        </div>
                        <div>
                            <a href="https://podcasters.spotify.com/pod/show/untrading" target="_blank" class="text-decoration-none" aria-label="GitHub">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0032faef-692f-45ff-f4bd-35d85887d300/public" width="24" height="24"></v-img>
                            </a>
                        </div>
                        <div class="px-2">
                            <a href="https://www.linkedin.com/company/untrading/" target="_blank" class="black--text del-underline" aria-label="Linkedin">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0b270d74-2b67-43b6-2a9a-9e9af19a6d00/public" width="24" height="24"></v-img>
                            </a>
                        </div>
                        <div>
                            <a href="https://www.youtube.com/@untrading" target="_blank" class="black--text del-underline" aria-label="Linkedin">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/83372301-c889-4b7b-440a-623d356abc00/public" width="24" height="24"></v-img>
                            </a>
                        </div>
                    </div>                
                </v-col>

                <v-col cols="12" class="footer-14 mt-10 text-primaryGrey px-0 lexend-deca">
                    This site is operated by Untrading Technologies Pte Ltd. and services are provided through its wholly-owned subsidiaries (altogether, “Untrading”). Untrading does not give investment advice, endorse or make recommendations with respect to any assets or provide legal or tax advice. Neither Untrading nor any of its officers, directors, agents, or employees makes any warranty, express or implied, of any kind whatsoever related to the adequacy, accuracy, or completeness of any information on this site or the use of information on this site. This site contains external links to third-party content (content hosted on sites unaffiliated with Untrading). As such, Untrading makes no representations or endorsements whatsoever regarding any third-party content/sites that may be accessible directly or indirectly from this site. Untrading services are only directed toward the residents of jurisdictions where such services are permitted. Some services may be limited to residents of certain jurisdictions, and disclosures may be required in specific jurisdictions. Use of the site is subject to certain risks, including but not limited to those listed here. Assets offered on the platform are not insured by the FDIC, SIPC, or any similar organization. Users must conduct their own due diligence of any digital asset. Participating in digital assets is highly risky and may lead to total loss of funds. By using this site, you explicitly agree to our Terms of Service and Privacy Policy. Untrading and its employees, officers, directors, and affiliates may have interests in assets listed on this site and may also participate in certain offerings using the site (where permitted).
                </v-col>
                <v-col cols="12" sm="12" md="12" class="mt-10 px-0">
                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="12" xl="12" xxl="8" class="px-0 d-flex r-flex-column">
                            <div>
                                <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank" class="del-underline pointer" aria-label="Licenses">
                                    <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/8554187d-f5dc-41f7-eb9e-637396d9d100/public" cover class="mb-3" width="80" alt="" />
                                </a>
                            </div>
                            <div class="footer-14 text-primaryGrey px-0 pl-4 r-p-l-0 lexend-deca">
                                Except where otherwise noted, content on this site is licensed under a Creative Commons Attribution 4.0 International license.
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<script>
import Media from '@/components/common/Media';
import Logo from '@/components/common/Logo';
import { mapGetters } from "vuex";
export default {
    data(){
        return {

        }
    },
    components: { Logo, Media },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["locale", "responsive"])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped lang="scss">
.pl-90 {
  padding-left: 90px !important;
}
</style>