<template>
	<div class="layoutContent lexend-deca">
        <div class="Grid_root__iRfoa grid-style-5">
            <div class="Grid_a__vY7M8">
                <h2 class="typography_h1 lexend-deca text-quaternary">
                    Redefining What It Means to Sell a Token.
                </h2>
            </div>
            <div class="Grid_b___vQi_ d-flex align-end text-tertiary r-m-t-16 fs-17">
                Ever kick yourself for selling too early? Not anymore. Your profits keep flowing even after you sell.
            </div>
        </div>
        <div style="background:rgb(var(--v-theme-bg-cfd));" role="none" aria-orientation="horizontal" data-orientation="horizontal" class="Separator_root__dpDqU mt-12"></div>
        <div class="Grid_root__iRfoa grid-style-6">
            <div class="Grid_a__vY7M8 page_bento">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Kingdoms</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">
                        On-chain communities united by shared goals and interests. Build and grow alongside members who share your vision.
                    </p>
                </div>
            </div>
            <div class="Grid_b___vQi_ page_bento">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Flows</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">
                        A reward system where sellers share profits with previous token holders. Earn rewards from future sales across multiple generations.
                    </p>
                </div>
            </div>
            <div class="Grid_c__Tv5Qw page_bento">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Together</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">
                        Strategic trading within communities to maximize shared rewards. Choose your trading partners to optimize value for everyone.
                    </p>
                </div>
            </div>
        </div>
        <div style="background:rgb(var(--v-theme-bg-cfd));" role="none" aria-orientation="horizontal" data-orientation="horizontal" class="Separator_root__dpDqU mb-16"></div>
        <div class="text-center">
            <p class="fs-21-bold text-black01">Kingdoms + Flows = <span class="text-primary">Continued Growth</span></p>
            <p class="fs-17 text-tertiary mt-8">Our core technologies are our novel Kingdoms and Flows. </p>
        </div>
	</div>
</template>
<script>
export default {
    data(){
        return {
           
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
    }
}
</script>
<style scoped>

</style>