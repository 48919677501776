<template>
    <div class="lexend-deca">
        <!-- <div class="extra-glow-header-right"></div> -->
        <v-app-bar fixed elevation="0" color="homeBg">
            <template v-slot:prepend>
                <v-card color="transparent" elevation="0" rounded="0">
                    <v-row no-gutters justify="space-between" align="center">
                        <div class="pl-10">
                            <router-link to="/" class="pointer">
                                <logo type="header" color="light" responsive="mobile"></logo>
                            </router-link>
                        </div>
                        <div class="ml-10">
                            <v-menu transition="slide-y-transition" offset="10" width="180" open-on-hover>
                                <template v-slot:activator="{ props }" >
                                    <span v-bind="props" class="text-primaryGrey pointer gradient-underline-hover">
                                        <span class="text-primaryGrey gradient-text-hover">Products</span>
                                    </span>
                                </template>
                                <v-list class="text-primaryGrey" bg-color="black">
                                    <v-list-item v-for="(menu, index) in productMenus" :key="index" :value="index">
                                        <router-link :to="menu.link" class="del-underline" v-if="menu.linkType == 'to'">
                                            <v-list-item-title class="gradient-underline-hover">
                                                <a class="ml-3 text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                                            </v-list-item-title>
                                        </router-link>
                                        <a :href="menu.link" class="del-underline" v-if="menu.linkType == 'a'" target="_blank">
                                            <v-list-item-title class="gradient-underline-hover">
                                                <a class="ml-3 text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                                            </v-list-item-title>
                                        </a>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        
                        <div class="ml-10 del-underline gradient-underline-hover">
                            <router-link to="/kols">
                                <span class="text-primaryGrey gradient-text-hover">KOLs</span>
                            </router-link>
                        </div>
                        <div class="ml-10 del-underline gradient-underline-hover">
                            <a href="https://kingdoms.untrading.org" target="_blank">
                                <span class="text-primaryGrey gradient-text-hover">Kingdoms</span>
                            </a>
                        </div>
                        <div class="ml-10">
                            <v-menu transition="slide-y-transition" offset="10" width="180" open-on-hover>
                                <template v-slot:activator="{ props }" >
                                    <span v-bind="props" class="text-primaryGrey pointer gradient-underline-hover">
                                        <span class="text-primaryGrey gradient-text-hover">Docs</span>
                                    </span>
                                </template>
                                <v-list class="text-primaryGrey" bg-color="black">
                                    <v-list-item v-for="(menu, index) in docMenus" :key="index" :value="index">
                                        <router-link :to="menu.link" class="del-underline" v-if="menu.linkType == 'to'">
                                            <v-list-item-title class="gradient-underline-hover">
                                                <a class="ml-3 text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                                            </v-list-item-title>
                                        </router-link>
                                        <a :href="menu.link" class="del-underline" v-if="menu.linkType == 'a'" target="_blank">
                                            <v-list-item-title class="gradient-underline-hover">
                                                <a class="ml-3 text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                                            </v-list-item-title>
                                        </a>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <div class="ml-10">
                            <v-menu transition="slide-y-transition" offset="10" width="180" open-on-hover>
                                <template v-slot:activator="{ props }" >
                                    <span v-bind="props" class="text-primaryGrey pointer gradient-underline-hover">
                                        <span class="text-primaryGrey gradient-text-hover">Developers</span>
                                    </span>
                                </template>
                                <v-list class="text-primaryGrey" bg-color="black">
                                    <v-list-item v-for="(menu, index) in developerMenus" :key="index" :value="index">
                                        <router-link :to="menu.link" class="del-underline" v-if="menu.linkType == 'to'">
                                            <v-list-item-title class="gradient-underline-hover">
                                                <a class="ml-3 text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                                            </v-list-item-title>
                                        </router-link>
                                        <a :href="menu.link" class="del-underline" v-if="menu.linkType == 'a'" target="_blank">
                                            <v-list-item-title class="gradient-underline-hover">
                                                <a class="ml-3 text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                                            </v-list-item-title>
                                        </a>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <div class="ml-10">
                            <v-menu transition="slide-y-transition" offset="10" width="180" open-on-hover>
                                <template v-slot:activator="{ props }" >
                                    <span v-bind="props" class="text-primaryGrey pointer gradient-underline-hover">
                                        <span class="text-primaryGrey gradient-text-hover">Company</span>
                                    </span>
                                </template>
                                <v-list class="text-primaryGrey" bg-color="black">
                                    <v-list-item v-for="(menu, index) in aboutMenus" :key="index" :value="index">
                                        <router-link :to="menu.link" class="del-underline" v-if="menu.linkType == 'to'">
                                            <v-list-item-title class="gradient-underline-hover">
                                                <a class="ml-3 text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                                            </v-list-item-title>
                                        </router-link>
                                        <a :href="menu.link" class="del-underline" v-if="menu.linkType == 'a'" target="_blank">
                                            <v-list-item-title class="gradient-underline-hover">
                                                <a class="ml-3 text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                                            </v-list-item-title>
                                        </a>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </v-row>
                </v-card>
            </template>
            <template v-slot:append>
                <div class="mr-8" v-if="false">
                    <v-menu transition="slide-y-transition" offset="10" width="180" open-on-hover>
                        <template v-slot:activator="{ props }" >
                            <span v-bind="props" class="text-primaryGrey pointer gradient-underline-hover">
                                <span class="text-primaryGrey gradient-text-hover">APPs</span>
                                <v-icon :icon="props['aria-expanded'] == 'true' ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'" size="24" class="ml-2 mb-1" />
                            </span>
                        </template>
                        <v-list class="text-primaryGrey" bg-color="black">
                            <v-list-item v-for="(menu, index) in appMenus" :key="index" :value="index">
                                <router-link :to="menu.link" class="del-underline" v-if="menu.linkType == 'to'">
                                    <v-list-item-title class="gradient-underline-hover">
                                        <a class="ml-3 text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                                    </v-list-item-title>
                                </router-link>
                                <a :href="menu.link" class="del-underline" v-if="menu.linkType == 'a'" target="_blank">
                                    <v-list-item-title class="gradient-underline-hover">
                                        <a class="ml-3 text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                                    </v-list-item-title>
                                </a>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
        </v-app-bar>
        <!-- 固定 Discord -->
        <a href="https://discord.gg/D5rZdhzVWh" target="_blank" class="del-underline" aria-label="Discord">
            <v-btn position="fixed" location="bottom right" class="mr-6 mb-12 z-index-999" icon variant="text" size="large">
                <v-menu v-model="fixedDiscordMenu" transition="slide-y-transition" :offset="10" location="top" open-on-hover contained>
                    <template v-slot:activator="{ props }" >
                        <div v-bind="props" >
                            <v-icon class="icon" color="secondary" size="32">icon-dis</v-icon>
                        </div>
                    </template>
                    <v-card class="pa-3 text-primaryGrey" rounded="0">
                        <h6 class="body-p font-weight-thin text-none">We are online! How may I help you today? </h6>
                    </v-card>
                </v-menu>
            </v-btn>
        </a>
        <!--固定举报作弊图标 -->
        <v-btn position="fixed" location="bottom right" class="mr-6 z-index-999" icon variant="text" size="large" @click="reportCheatingDialog = true">
            <v-menu v-model="fixedReportCheatingMenu" transition="slide-y-transition" :offset="10" location="top" open-on-hover contained>
                <template v-slot:activator="{ props }" >
                    <div v-bind="props" >
                        <v-icon class="icon" color="primaryGrey" size="32">icon-alert</v-icon>
                    </div>
                </template>
                <v-card class="pa-3 text-primaryGrey" rounded="0">
                    <h6 class="body-p font-weight-thin text-none">Report Cheating</h6>
                </v-card>
            </v-menu>
        </v-btn>
        <!-- 举报作弊弹窗 -->
        <ReportCheating :show="reportCheatingDialog" @watchReportCheatingDialog="watchReportCheatingDialog"></ReportCheating>
        <!-- 消息条 -->
        <Snackbar></Snackbar>
    </div>
</template>
<script>
import Logo from '@/components/common/Logo';
import Theme from '@/components/common/Theme';
import ReportCheating from '@/components/common/ReportCheating';
import Snackbar from '@/components/common/Snackbar';
import { mapGetters } from "vuex";
import { mergeProps } from 'vue'
export default {
    data(){
        return {
            // 举报作弊弹窗
            reportCheatingDialog: false,
            // 当前 URL
            currentUrl: window.location.href,
            // 固定 Discord 菜单
            fixedDiscordMenu: false,
            // 固定举报作弊菜单
            fixedReportCheatingMenu: false
        }
    },
    components: { Logo, ReportCheating, Theme, Snackbar },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'token']),
        // 产品菜单
        productMenus() {
            let menus = [
                { title: 'Testnet', iconType: 'icon', icon: null, linkType: 'a', link: 'https://testnet.untrading.org' },
                { title: 'Demo', iconType: 'icon', icon: null, linkType: 'a', link: 'https://demo.untrading.org' },
            ];
            return menus;
        },
        // 关于菜单
        aboutMenus() {
            let menus = [
                { title: 'About', iconType: 'icon', icon: null, linkType: 'to', link: '/about' },
                { title: 'Network DeFi', iconType: 'icon', icon: null, linkType: 'to', link: '/network-defi' },
                { title: 'FAQs', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/faqs' },
                { title: 'Terms of Service', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/terms-of-service' },
                { title: 'Privacy Policy', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/privacy-policy' },
                { title: 'Legal', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/risks' },
                { title: '5173 Foundation', iconType: 'icon', icon: null, linkType: 'a', link: 'https://5173.foundation' },
                { title: 'UN Token', iconType: 'icon', icon: null, linkType: 'to', link: '/un' },
            ];
            return menus;
        },
        // 文档菜单
        docMenus() {
            let menus = [
                { title: 'ERC-5173', iconType: 'icon', icon: null, linkType: 'a', link: 'https://eips.ethereum.org/EIPS/eip-5173' },
                { title: 'Whitepaper', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/whitepaper' },
                { title: 'Tutorials', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/tutorials' },
            ];
            return menus;
        },
        // 开发者菜单
        developerMenus() {
            let menus = [
                { title: 'Documentation', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org' },
                { title: 'GitHub', iconType: 'icon', icon: null, linkType: 'a', link: 'https://github.com/untrading' },
            ];
            return menus;
        },
        // App 菜单
        appMenus() {
            let menus = [
                { title: 'unCryptos', iconType: 'icon', icon: null, linkType: 'to', link: '/uncryptos' },
                { title: 'unNFTs', iconType: 'icon', icon: null, linkType: 'to', link: '/unnfts' },
            ];
            return menus;
        },
    },
    watch:{
        $route: {
            handler(n, o) {
                this.currentUrl = window.location.href;
            },
            immediate: true
        },
    },
    methods: {
        // 监听举报作弊弹窗
        watchReportCheatingDialog(show) {
            this.reportCheatingDialog = show;
        },
        mergeProps
    }
}
</script>
<style scoped>
</style>