<template>
    <div class="h-100 pa-4">
        <!-- <div class="header-common">
            <v-icon start icon="mdi mdi-chevron-left" color="primary" size="x-large" @click="this.$router.go(-1)"></v-icon>
            <div class="text text-primaryGrey">Search</div>
            <v-avatar icon size="30">
                <v-icon v-if="!user.avatar" color="primary" icon="mdi:mdi-account-circle-outline" size="30"></v-icon>
                <v-img v-if="user.avatar" :src="user.avatar" cover height="30"></v-img>
            </v-avatar>
        </div> -->
        <div>
            <!-- <v-text-field style="border: none;" base-color="surface" bg-color="surface" class="body-p" density="comfortable"
                variant="outlined" v-model="text" placeholder="Search"
                :append-inner-icon="menu ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'" clear-icon="mdi mdi-close"
                @click:append-inner="showMenu"></v-text-field> -->
                
                <v-text-field style="border: none;" base-color="surface" bg-color="surface" class="body-p" density="comfortable"
                variant="outlined" v-model="text" placeholder="Search"></v-text-field>
        </div>
        <div>
            <v-card class="pa-4 w-100" rounded="0">
                <div class="d-flex justify-center">
                    <v-card width="100%" color="transparent" rounded="0" elevation="0" class="px-2">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <h2 class="body-text-btn text-primaryGrey">
                                    Filters
                                </h2>
                            </v-col>
                            <v-col cols="12" class="mt-2">
                                <!-- 区块链 -->
                                <v-select v-model="searchParam.blockchain" placeholder="Blockchain" :items="blockchains" item-title="blockchain" item-value="blockchain" rounded="0" class="body-p-small font-weight-bold" density="comfortable" variant="outlined" clearable hide-details>
                                    <template v-slot:selection="{ item }">
                                        <img class="mr-3" :src="item.raw.icon" width="24" />
                                        <span>{{ item.title }}</span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" class="mt-2">
                                <!-- 盈利预期 -->
                                <v-select v-model="searchParam.cryptoProfitExpectancy" placeholder="Profit Expectancy (PE)" :items="cryptoProfitExpectancies" item-value="name" rounded="0" class="body-p-small font-weight-bold" density="comfortable" variant="outlined" clearable hide-details>
                                    <template v-slot:selection="{ item }">
                                        <span>{{ `${item.raw.name} (+${item.raw.profitLowestPriceChangeRatio * 100}%)`}}</span>
                                    </template>
                                    <template v-slot:item="{ props, item }">
                                        <v-list-item v-bind="props">
                                            <template v-slot:title>
                                                <span>{{ `${props.key + 1} - ${item.raw.name} (+${item.raw.profitLowestPriceChangeRatio * 100}%)`}}</span>
                                            </template>
                                        </v-list-item>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" class="mt-2">
                                <div class="d-flex align-center justify-center">
                                    <v-select v-model="searchParam.wrappedCurrency" :items="cryptoProducts" item-title="currency" item-value="currency"
                                        hide-details placeholder="Select token" rounded="0"
                                        class="body-p-small font-weight-bold" density="comfortable" variant="outlined">
                                        <template v-slot:item="{ props, item }">
                                            <v-list-item v-bind="props">
                                                <template v-slot:title>
                                                    <span class="mr-2">{{ item.raw.currency }}</span>
                                                    <span class="mr-2">({{ item.raw.name }})</span>
                                                </template>
                                            </v-list-item>
                                        </template>
                                    </v-select>
                                    <div class="home-h3 text-primaryGrey mx-5">/</div>
                                    <v-select v-model="searchParam.quoteCurrency" :items="cryptoQuotes" item-title="currency" item-value="currency"
                                        hide-details placeholder="Select token" rounded="0"
                                        class="body-p-small font-weight-bold" density="comfortable" variant="outlined">
                                        <template v-slot:item="{ props, item }">
                                            <v-list-item v-bind="props">
                                                <template v-slot:title>
                                                    <span class="mr-2">{{ item.raw.currency }}</span>
                                                    <span class="mr-2">({{ item.raw.name }})</span>
                                                </template>
                                            </v-list-item>
                                        </template>
                                    </v-select>
                                </div>
                            </v-col>
                            <v-col cols="12 mt-6">
                                <h2 class="body-text-btn text-primaryGrey">
                                    nFR Parameters
                                </h2>
                            </v-col>
                            <v-col cols="12" class="pa-0 mt-4">
                                <h3 class="body-h4 text-primaryGrey">Total Reward
                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                        <template v-slot:activator="{ props }">
                                            <v-icon icon="mdi:mdi-alert-circle-outline"
                                                class="text-primaryGrey pointer gradient-text-hover"
                                                v-bind="props"></v-icon>
                                        </template>
                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                            <!-- {{ $t('common.totalReward') }} -->
                                            The total Rewards available for distribution as a percentage of the
                                            total realized profit each time an owner sells this unNFT.
                                        </v-card>
                                    </v-menu>
                                </h3>
                                <div class="mt-2">
                                    <v-range-slider v-model="searchParam.totalRewardRatios" color="primary" track-color="black01"
                                        track-size="1" thumb-size="16" step="1" min="5" max="50" density="compact"
                                        hide-details thumb-label>
                                        <template v-slot:thumb-label="{ modelValue }">
                                            {{ modelValue }}%
                                        </template>
                                    </v-range-slider>
                                </div>
                            </v-col>
                            <v-col cols="12" class="pa-0 mt-4">
                                <h3 class="body-h4 text-primaryGrey">Future Rewards (FR)
                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                        <template v-slot:activator="{ props }">
                                            <v-icon icon="mdi:mdi-alert-circle-outline"
                                                class="text-primaryGrey pointer gradient-text-hover"
                                                v-bind="props"></v-icon>
                                        </template>
                                        <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                            <!-- {{ $t('common.futureRewards') }} -->
                                            Future Rewards (FRs) are paid to previous owners and buyers based on the
                                            remaining profits. Future Rewards (FRs) = Total Rewards (R) -
                                            Originators' Rewards (ORs).
                                        </v-card>
                                    </v-menu>
                                </h3>
                                <div class="mt-2">
                                    <v-range-slider v-model="searchParam.futureRewardRatios" color="primary" track-color="black01"
                                        track-size="1" thumb-size="16" step="1" min="88" max="95" density="compact"
                                        hide-details thumb-label>
                                        <template v-slot:thumb-label="{ modelValue }">
                                            {{ modelValue }}%
                                        </template>
                                    </v-range-slider>
                                </div>
                            </v-col>
                            <v-col cols="12" class="pa-0 mt-4">
                                <h3 class="body-h4 text-primaryGrey"># of generations
                                    <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                        <template v-slot:activator="{ props }">
                                            <v-icon icon="mdi:mdi-alert-circle-outline"
                                                class="text-primaryGrey pointer gradient-text-hover"
                                                v-bind="props"></v-icon>
                                        </template>
                                        <v-card max-width="80%" class="pa-3 text-primaryGrey body-p" rounded="0">
                                            <!-- {{ $t('common.ofGenerations') }} -->
                                            The number of generations of owners who are eligible for the Future
                                            Rewards (FRs) distribution each time an owner has a realized profit.
                                        </v-card>
                                    </v-menu>
                                </h3>
                                <div class="mt-2">
                                    <v-range-slider v-model="searchParam.numberOfGenerations" color="primary" track-color="black01"
                                        track-size="1" thumb-size="16" step="1" min="10" max="30" density="compact"
                                        hide-details thumb-label></v-range-slider>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
            </v-card>
        </div>
        <div class="w-100 mt-8" style="text-align: center; font-size: 20px;">
            <v-icon icon="mdi mdi-magnify" color="green01" @click="onSearch"></v-icon>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Avatar from '@/components/user/mobile/Avatar';
import CryptoProfitExpectancyAPI from '@/api/crypto-profit-expectancy.js';
export default {
    data() {
        return {
            menu: true,
            // 加密货币利润预期集合
            cryptoProfitExpectancies: [],
            // 查询文本
            text: null,
            // 搜索条件
            searchParam: {
                blockchain: null,
                wrappedCurrency: 'unBTC',
                quoteCurrency: 'USDT',
                totalRewardRatios: [10,40],
                futureRewardRatios: [90,95],
                numberOfGenerations: [10,30]
            },
            // 加密货币报价资产选择框
            cryptoQuotes: [
                { currency: 'USDT', name: 'USDT' },
                { currency: 'USDC', name: 'USD Coin' }
            ],
        }
    },
    components: { Avatar },
    created() {

    },
    mounted() {
        // 查询全部加密货币盈利预期
        this.getCryptoProfitExpectancies();
    },
    computed: {
        ...mapGetters(['user', 'blockchains', 'cryptoProducts']),
    },
    watch: {

    },
    methods: {
        showMenu() {
            if (this.menu) {
                this.menu = false
            } else {
                this.menu = true
            }
        },
        // 查询全部加密货币盈利预期
        async getCryptoProfitExpectancies() {
            this.cryptoProfitExpectancies = [];
            let res = await CryptoProfitExpectancyAPI.getCryptoProfitExpectancies();
            let data = res.data;
            if(data.success) {
                this.cryptoProfitExpectancies = data.data;
            }
        },
        // 跳转到搜索页面
        onSearch() {
            this.$store.dispatch('searchHandler', this.searchParam);
            this.$router.push('/uncryptos/search/results' + ((this.text != null && this.text.length > 0) ? ('?text=' + this.text) : ''));
            // 通知刷新数据
            this.$bus.emit('emitRefresh');
        }
    }
}
</script>
<style scoped>
.header-common {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text {
    font-size: 18px;
    text-align: center;
    font-family: Montserrat;
    font-weight: 600;
}
</style>